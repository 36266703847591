import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FeatherModule } from "angular-feather";

import {
  ArrowLeft,
  ArrowRight,
  AlertCircle,
  ChevronLeft,
  ChevronRight,
  CheckCircle,
  Filter,
  Heart,
  Info,
  Minimize,
  Maximize,
  Play,
  Search,
  Share2,
  X,
} from "angular-feather/icons";

const icons = {
  ArrowLeft,
  ArrowRight,
  AlertCircle,
  ChevronLeft,
  ChevronRight,
  CheckCircle,
  Filter,
  Heart,
  Info,
  Minimize,
  Maximize,
  Play,
  Search,
  Share2,
  X,
};

@NgModule({
  declarations: [],
  imports: [FeatherModule.pick(icons), CommonModule],
  exports: [FeatherModule],
})
export class IconsModule {}
