import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { DeviceTypeInitProduct } from "../enums/device-type.enum";

@Injectable({
  providedIn: "root",
})
export class DetectDeviceService {
  constructor(private _deviceService: DeviceDetectorService) {}

  public getDeviceType() {
    let deviceType: DeviceTypeInitProduct;
    const deviceInfo = this._deviceService.getDeviceInfo().os;

    if (this._deviceService.isMobile()) {
      if (deviceInfo == "iOS") {
        deviceType = DeviceTypeInitProduct.IOSMOBILE;
      } else if (deviceInfo == "Android") {
        deviceType = DeviceTypeInitProduct.ANDROID;
      } else if (deviceInfo == "Windows-Phone") {
        deviceType = DeviceTypeInitProduct.WINDOWSPHONE;
      } else {
        deviceType = DeviceTypeInitProduct.ANDROID;
      }
    } else {
      deviceType = DeviceTypeInitProduct.PC;
    }
    return deviceType;
  }

  public getIsMobile() {
    return this._deviceService.isMobile();
  }
}
