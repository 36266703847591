import { createAction, props } from "@ngrx/store";
import { Game } from "src/app/models/games.model";
import { Bonus } from "../model";

export const loadBonusesAction = createAction("[Bonuses] Load Bonuses");

export const addBonusesAction = createAction(
  "[Bonuses] Add Bonuses",
  props<{ bonuses: Bonus[] }>()
);

export const addCurrentBonusAction = createAction(
  "[Bonuses] Add Current Bonus",
  props<{ bonus: Bonus; games: Game[] }>()
);

export const openModalCancelBonusAction = createAction(
  "[Bonuses] Open Modal Cancel Bonus",
  props<{ open: boolean }>()
);

export const openModalSuccessActivationAction = createAction(
  "[Bonuses] Open Success Activation",
  props<{ open: boolean }>()
);

export const openModalCurrentBonusAction = createAction(
  "[Bonuses] Open Modal Current Bonus",
  props<{ open: boolean }>()
);
