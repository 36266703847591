import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Game } from "src/app/models/games.model";

import { GamesService } from "src/app/services/games.service";
import { debounceTime } from "rxjs/operators";
import { Store } from "@ngrx/store";

import { AppState } from "src/app/models/state.model";
import { UiLayoutState } from "src/app/state/ui-layout/model";
import { selectUiLayoutState } from "src/app/state/ui-layout/selector/ui-layout.selector";

import { selectAllGamesUser } from "src/app/state/costumer/selector/customer.selector";
import { setAllGamesUserAction } from "src/app/state/costumer/actions/customer.action";
import { EmptyStateType } from "src/app/enums/empty-state.enum";

import { Subject, Subscription } from "rxjs";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  public allGames: Game[] = [];
  public uiLayout: UiLayoutState;
  public search = new FormControl("");

  public searchResults: Game[] = [];
  public EmptyStateType = EmptyStateType;
  public showSearch: boolean = false;

  public isEmpty: boolean;
  public ngDestroyed$ = new Subject();
  private uiStateSubscription: Subscription;

  private allGamesSubscription: Subscription;

  constructor(
    private _gamesService: GamesService,
    private _store: Store<AppState>
  ) {
    this.uiStateSubscription = this._store
      .select(selectUiLayoutState)
      .subscribe((state) => {
        this.uiLayout = state;
        this.showSearch = !state.brandConfiguration.layout.hideSearch;
      });

    this.allGamesSubscription = this._store
      .select(selectAllGamesUser)
      .subscribe((state) => {
        this.allGames = state;
      });
  }

  ngOnInit() {
    this.showSearch ? this.loadData() : null;
    this.search.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value: string) => {
        if (value.length) {
          this.searchResults = this.allGames.filter((game) =>
            game.name.toLowerCase().includes(value.toLowerCase())
          );
          this.isEmpty = !this.searchResults.length;
        } else {
          this.searchResults = [];
          this.isEmpty = false;
        }
      });
  }

  public ngOnDestroy() {
    this.uiStateSubscription.unsubscribe();
    this.allGamesSubscription.unsubscribe();
  }

  private loadData(): void {
    if (!this.allGames.length) {
      this._gamesService
        .getGames({
          operatorID: this.uiLayout.brandConfiguration.operatorId,
          category:
            this.uiLayout.brandConfiguration.categoryRecommendation || "",
          vendor: "",
          sort: "",
          tags: "",
          search: null,
          platform: this.uiLayout.platform.toString(),
          pageSize: 10000,
          pageIndex: 1,
        })
        .subscribe((data) => {
          if (data && data.games.length) {
            this._store.dispatch(
              setAllGamesUserAction({
                allGames: data.games,
              })
            );
          }
        });
    }
  }
}
