import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

import { Observable } from "rxjs";
import {
  CustomerGetFavoriteGamesResponse,
  CustomerGetLastPlayedGamesResponse,
  CustomerRemoveFavoriteGameResponse,
  CustomerSetFavoriteGameResponse,
  CustomerTokenPayload,
  CustomerTokenResponse,
  GetFavoriteGamesPayload,
  GetLastPlayedGamesPayload,
  RemoveFavoriteGamePayload,
  SetFavoriteGamePayload,
} from "../state/costumer/model";
import {
  VendorsCountPayload,
  VendorsCountResponse,
} from "../models/vendors-count.model";

import {
  CategoriesCountPayload,
  CategoriesCountResponse,
} from "../models/categories-count.model";
import { Store } from "@ngrx/store";
import { AppState } from "../models/state.model";

import { selectCustomerState } from "../state/costumer/selector/customer.selector";
import {
  GetOperatorInfoPayload,
  OperatorInfoResponse,
} from "../state/ui-layout/model";

@Injectable({ providedIn: "root" })
export class GamificationService {
  private customerTokenApiUrl: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.LOGIN;

  private getFavoriteGamesApiUrl: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.GET_FAVORITE_GAMES;

  private setFavoriteGameApiUrl: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.SET_FAVORITE_GAME;

  private removeFavoriteGameApiUrl: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.REMOVE_FAVORITE_GAME;

  private getCategoryCountApiUrl: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.CATEGORY_COUNT;

  private getVendorCountApiUrl: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.VENDOR_COUNT;

  private getOperatorInfoApiUrl: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.GET_OPERATOR_INFO;

  private GET_LAST_PLAYED_GAMES_API: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.GET_LAST_PLAYED_GAMES;

  public language: string;
  public country: string;
  public UID: string;

  public agentId: string;

  constructor(public http: HttpClient, private store: Store<AppState>) {
    this.store.select(selectCustomerState).subscribe((state) => {
      this.language = state.language;
      this.country = state.country;
      this.UID = state.playerId;
      this.agentId = state.agentId;
    });
  }

  public getToken(
    data: CustomerTokenPayload
  ): Observable<CustomerTokenResponse> {
    return this.http.post<CustomerTokenResponse>(
      this.customerTokenApiUrl,
      JSON.stringify(data)
    );
  }

  public getFavoriteGames(
    data: GetFavoriteGamesPayload
  ): Observable<CustomerGetFavoriteGamesResponse> {
    return this.http.post<CustomerGetFavoriteGamesResponse>(
      this.getFavoriteGamesApiUrl,
      JSON.stringify({
        ...data,
        UID: this.UID || "",
        agentId: this.agentId || "",
      })
    );
  }

  public getLastPlayedGames(
    data: GetLastPlayedGamesPayload
  ): Observable<CustomerGetLastPlayedGamesResponse> {
    return this.http.post<CustomerGetLastPlayedGamesResponse>(
      this.GET_LAST_PLAYED_GAMES_API,
      JSON.stringify({
        ...data,
        UID: this.UID || "",
        agentId: this.agentId || "",
      })
    );
  }

  public setFavoriteGame(
    data: SetFavoriteGamePayload
  ): Observable<CustomerSetFavoriteGameResponse> {
    return this.http.post<CustomerSetFavoriteGameResponse>(
      this.setFavoriteGameApiUrl,
      JSON.stringify(data)
    );
  }

  public removeFavoriteGame(
    data: RemoveFavoriteGamePayload
  ): Observable<CustomerRemoveFavoriteGameResponse> {
    return this.http.post<CustomerRemoveFavoriteGameResponse>(
      this.removeFavoriteGameApiUrl,
      JSON.stringify(data)
    );
  }

  public getCategoriesCount(
    data: CategoriesCountPayload
  ): Observable<CategoriesCountResponse> {
    return this.http.post<CategoriesCountResponse>(
      this.getCategoryCountApiUrl,
      JSON.stringify({
        ...data,
        UID: this.UID || "",
        agentId: this.agentId || "",
        country: this.country || undefined,
        language: this.language || undefined,
      })
    );
  }

  public getVendorsCount(
    data: VendorsCountPayload
  ): Observable<VendorsCountResponse> {
    return this.http.post<VendorsCountResponse>(
      this.getVendorCountApiUrl,
      JSON.stringify({
        ...data,
        UID: this.UID || "",
        agentId: this.agentId || "",
        country: this.country || undefined,
        language: this.language || undefined,
      })
    );
  }

  public getOperatorInfo(
    data: GetOperatorInfoPayload
  ): Observable<OperatorInfoResponse> {
    return this.http.post<OperatorInfoResponse>(
      this.getOperatorInfoApiUrl,
      JSON.stringify(data)
    );
  }
}
