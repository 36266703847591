import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

import { Observable } from "rxjs";
import {
  CustomerProvidersGamificationPayload,
  CustomerProvidersGamificationResponse,
} from "../models/providers.model";
import { AppState } from "../models/state.model";

import { Store } from "@ngrx/store";
import { selectUiLayoutState } from "../state/ui-layout/selector/ui-layout.selector";
import { BrandConfiguration } from "../state/ui-layout/model";

import { selectCustomerState } from "../state/costumer/selector/customer.selector";

@Injectable({ providedIn: "root" })
export class ProvidersService {
  private GET_PROVIDERS_GAMIFICATION_API: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.PROVIDERS;
  public brandConfiguration: BrandConfiguration;
  public UID: string;
  public agentId: string;

  constructor(public http: HttpClient, private _store: Store<AppState>) {
    this._store.select(selectUiLayoutState).subscribe((state) => {
      this.brandConfiguration = state.brandConfiguration;
    });
    this._store.select(selectCustomerState).subscribe((state) => {
      this.UID = state.playerId;
      this.agentId = state.agentId;
    });
  }

  public getProvidersGamification(
    data: CustomerProvidersGamificationPayload
  ): Observable<CustomerProvidersGamificationResponse> {
    return this.http.post<CustomerProvidersGamificationResponse>(
      this.GET_PROVIDERS_GAMIFICATION_API +
        `?operatorID=${this.brandConfiguration.operatorId}`,
      JSON.stringify({
        ...data,
        UID: this.UID || "",
        agentId: this.agentId || "",
      })
    );
  }
}
