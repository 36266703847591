import { Component, Input, OnInit } from "@angular/core";
import { Pagination } from "src/app/models/pagination.model";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Input() pagination: Pagination = {
    totalPages: 0,
    totalRows: 0,
    pageIndex: 0,
  };
  @Input() changePage: (pageIndex: number) => void;

  constructor() {}

  ngOnInit() {}

  nextPage() {
    this.changePage(this.pagination.pageIndex + 1);
  }

  previousPage() {
    this.changePage(
      this.pagination.pageIndex >= 1 ? this.pagination.pageIndex - 1 : 1
    );
  }
}
