export const providerOrderForBetamaAndIBetPix = [
  "Evolution",
  "Pragmatic Play",
  "Ortiz",
  "Zitro",
  "Caleta",
  "Spribe",
  "Ezugi",
  "Mancala",
  "Bgaming",
  "Betsoft",
  "Pragmatic Live",
  "Habanero",
  "Playson",
];
