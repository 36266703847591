import { createSelector } from "@ngrx/store";
import { CustomerState } from "../model";
import { AppState } from "../../app.model";

export const selectCustomerState = (state: AppState) => state.customer;

export const selectFavoriteGamesCustomer = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.favoriteGames
);

export const selectLastPlayedGamesCustomer = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.lastPlayedGames
);

export const selectTokenCustomer = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.userToken
);

export const selectLanguageCustomer = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.language
);

export const selectCountryCustomer = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.country
);

export const selectPlayerIdCustomer = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.playerId
);

export const selectGamificationToken = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.gamificationToken
);

export const selectCustomerProviders = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.customerProviders
);

export const selectIsDemoUser = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.isDemoUser
);

export const selectAllGamesUser = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.allGames
);
