import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

import { Observable } from "rxjs";
import { GamePayload, GameResponse } from "../models/games.model";
import { InitProductService } from "./initProduct.service";

import { selectUiLayoutState } from "../state/ui-layout/selector/ui-layout.selector";
import { BrandConfiguration } from "../state/ui-layout/model";
import { AppState } from "../state/app.model";

import { Store } from "@ngrx/store";
import { DeviceTypeInitProduct } from "../enums/device-type.enum";
import { DetectDeviceService } from "./detect-device.service";

import { updateIsLoadingAction } from "../state/ui-layout/actions/ui-layout.action";
import { selectCustomerState } from "../state/costumer/selector/customer.selector";

@Injectable({ providedIn: "root" })
export class GameService {
  private GET_GAME_API: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.GAME;

  public brandConfiguration: BrandConfiguration;
  public deviceType: DeviceTypeInitProduct;
  public language: string;

  public UID: string;
  public agentId: string;

  constructor(
    public http: HttpClient,
    private _initProduct: InitProductService,
    private _store: Store<AppState>,
    private _detectDeviceService: DetectDeviceService
  ) {
    this._store.select(selectUiLayoutState).subscribe((state) => {
      this.brandConfiguration = state.brandConfiguration;
    });
    this._store.select(selectCustomerState).subscribe((state) => {
      this.language = state.language;
      this.UID = state.playerId;
      this.agentId = state.agentId;
    });
  }
  ngOnInit() {
    this.deviceType = this._detectDeviceService.getDeviceType();
  }

  public getGame(data: GamePayload): Observable<GameResponse> {
    return this.http.post<GameResponse>(
      this.GET_GAME_API,
      JSON.stringify({
        ...data,
        language: this.language || undefined,
        UID: this.UID || "",
        agentId: this.agentId || "",
      })
    );
  }

  public openNewTab(id: string, bonusId?: number) {
    this._store.dispatch(updateIsLoadingAction({ isLoading: true }));
    this.getGame({
      operatorID: this.brandConfiguration.operatorId,
      externalID: id,
    }).subscribe((data) => {
      if (data) {
        this._initProduct
          .initProduct({
            SiteId: this.brandConfiguration.siteID,
            ProductId: parseInt(data.vendorExternalID),
            DeviceId: this.deviceType,
            GameId: parseInt(data.gameExternalID),
            BonusWallet: bonusId ? { id: bonusId } : null,
          })
          .subscribe((data) => {
            this._store.dispatch(updateIsLoadingAction({ isLoading: false }));
            if (data && data.Url) {
              window.open(atob(data.Url), "_blank");
            }
          });
      }
    });
  }
}
