// DEV
export const environment = {
  CLIENT_ID: "JAZZ_LOBBY_V1",
  SOCKET_SERVER_URL: "https://jzapi.jazzsports.com",
  JAZZ_APIS: {
    SERVER_URL: "https://justpay.bettingcenter.ag",
    APIS: {
      BONUSES: "/api/Player/GetMyBonuses",
      CANCEL_BONUS: "/api/Player/CancelMyBonuses",
      INIT_PRODUCT: "/api/Player/InitProduct",
      ADD_BONUS: "/api/player/SetBonus",
      DEPOSIT_VALIDATION: "/api/player/DepositValidation",
    },
  },
  BONUS_ENGINE_API: {
    SERVER_URL: "https://jazzapidev.azure-api.net/bonus-engine/",
    APIS: {
      BONUSES: "/api/Player/GetMyBonuses",
      CANCEL_BONUS: "/api/Player/CancelMyBonuses",
    },
  },
  PROVIDER_INTEGRATION_API: {
    SERVER_URL: "https://jazzapidev.azure-api.net/providers-integration",
    TOKEN: "a844265cc9a74264864bdabdee4aae93",
    APIS: {
      GAME_LAUNCH: "/game-launch",
    },
  },
  GAMIFICATION_API: {
    USERNAME: "token",
    PASSWORD: "T66*9j*gW7Uw",
    SERVER_URL: "https://jazzapidev.azure-api.net/gamification",
    APIS: {
      GET_FAVORITE_GAMES: "/customers/listFavoriteGame",
      SET_FAVORITE_GAME: "/customers/setFavoriteGame",
      REMOVE_FAVORITE_GAME: "/customers/removeFavoriteGame",
      GET_LAST_PLAYED_GAMES: "/customers/listGamesHistory",
      GET_BANNERS: "/banner/list",
      VENDOR_COUNT: "/casinoGame/vendorsCount",
      CATEGORY_COUNT: "/casinoGame/categoriesCount",
      GET_LOBBY_BLOCKS: "/lobbyblock/list",
      GET_OPERATOR_INFO: "/operator/details",
      GAME: "/casinoGame/game",
      LOBBY: "/casinoGame/lobby",
      GAMES: "/casinoGame/games",
      PROVIDERS: "/casinoGame/vendors",
      LOGIN: "/Users/authenticate",
    },
  },
  CLIENTS: {
    JAZZ: {
      OPERATOR_ID: 17,
      SITE_ID: 786,
    },
    TICO_BETS: {
      OPERATOR_ID: 2,
      SITE_ID: 742,
    },
    JAZZ_SPORTS: {
      OPERATOR_ID: 3,
      SITE_ID: 1,
    },
    LOOSE_LINES: {
      OPERATOR_ID: 4,
      SITE_ID: 3,
    },
    ABC_ISLANDS: {
      OPERATOR_ID: 5,
      SITE_ID: 4,
    },
    COCK_FIGHTS: {
      OPERATOR_ID: 6,
      SITE_ID: 939,
    },
    VIPER_LINES: {
      OPERATOR_ID: 7,
      SITE_ID: 927,
    },
    BET_CRYPTO: {
      OPERATOR_ID: 8,
      SITE_ID: 940,
    },
    GG_BETS: {
      OPERATOR_ID: 9,
      SITE_ID: 945,
    },
    BETAMA: {
      OPERATOR_ID: 10,
      SITE_ID: 930,
    },
    VIP_SPORTSBOOK: {
      OPERATOR_ID: 11,
      SITE_ID: 950,
    },
    BOSS_BET: {
      OPERATOR_ID: 12,
      SITE_ID: 951,
    },
    I_BET_SPORTS_COM: {
      OPERATOR_ID: 14,
      SITE_ID: 900,
    },
    I_BET_PIX: {
      OPERATOR_ID: 15,
      SITE_ID: 966,
    },
    I_BET_SPORTS_AG: {
      OPERATOR_ID: 16,
      SITE_ID: 901,
    },
    YO_GANO: {
      OPERATOR_ID: 18,
      SITE_ID: 973,
    },
    BET_VIP_MX: {
      OPERATOR_ID: 20,
      SITE_ID: 985,
    },
    MR_REELS: {
      OPERATOR_ID: 19,
      SITE_ID: 970,
    },
    ABC_WAGERING_AG: {
      OPERATOR_ID: 21,
      SITE_ID: 11,
    },
    BET_BIG_CITY: {
      OPERATOR_ID: 22,
      SITE_ID: 2,
    },
    BEACH_SB: {
      OPERATOR_ID: 23,
      SITE_ID: 954,
    },
    PLAY_CR: {
      OPERATOR_ID: 24,
      SITE_ID: 952,
    },
    PLAY_JOON_BUG: {
      OPERATOR_ID: 25,
      SITE_ID: 956,
    },
    PLAY_PL: {
      OPERATOR_ID: 26,
      SITE_ID: 957,
    },
    WAGER_911: {
      OPERATOR_ID: 27,
      SITE_ID: 958,
    },
    WAGER_CONNECTION: {
      OPERATOR_ID: 28,
      SITE_ID: 959,
    },
    WAGER_INTERNATIONAL: {
      OPERATOR_ID: 29,
      SITE_ID: 960,
    },
    WAGER_STORE: {
      OPERATOR_ID: 30,
      SITE_ID: 961,
    },
    WAGER_YOUR_MOJO: {
      OPERATOR_ID: 31,
      SITE_ID: 962,
    },
    SPORTSBOOK_CASINO_AND_RACEBOOK: {
      OPERATOR_ID: 32,
      SITE_ID: 933,
    },
    LUCKY_CRAW_FISH: {
      OPERATOR_ID: 33,
      SITE_ID: 981,
    },
    VANGUARD_SPORTS: {
      OPERATOR_ID: 34,
      SITE_ID: 982,
    },
    THIRD_DOWN: {
      OPERATOR_ID: 35,
      SITE_ID: 978,
    },
    TOSS_AND_GO: {
      OPERATOR_ID: 36,
      SITE_ID: 983,
    },
    DUBLIN_DAZE: {
      OPERATOR_ID: 37,
      SITE_ID: 972,
    },
    BEAT_THE_SPREAD: {
      OPERATOR_ID: 38,
      SITE_ID: 974,
    },
    WIN_AND_GRIN: {
      OPERATOR_ID: 39,
      SITE_ID: 975,
    },
    CLUB_PLAYER_24_7: {
      OPERATOR_ID: 40,
      SITE_ID: 976,
    },
    DUBLIN_DAYS: {
      OPERATOR_ID: 41,
      SITE_ID: 980,
    },
    DFW_COLLEGE: {
      OPERATOR_ID: 42,
      SITE_ID: 979,
    },
    BEAT_THE_TOTAL: {
      OPERATOR_ID: 43,
      SITE_ID: 977,
    },
    JET_BET: {
      OPERATOR_ID: 44,
      SITE_ID: 991,
    },
    HIGH_ROLLERS_AG: {
      OPERATOR_ID: 45,
      SITE_ID: 998,
    },
    BE_BET_AG: {
      OPERATOR_ID: 46,
      SITE_ID: 997,
    },
    RAZR_CASINO: {
      OPERATOR_ID: 47,
      SITE_ID: 914,
    },
    BETS_AMERICA_007: {
      OPERATOR_ID: 49,
      SITE_ID: 992,
    },
    BOOKIE_PRIME: {
      OPERATOR_ID: 51,
      SITE_ID: 911,
    },
    PREMIER_WAGER: {
      OPERATOR_ID: 52,
      SITE_ID: 1000,
    },
    JUGA_EN_CASA: {
      OPERATOR_ID: 53,
      SITE_ID: 1002,
    },
    EMPELOTADOS_COM: {
      OPERATOR_ID: 50,
      SITE_ID: 848,
    },
    EMPELOTADOS_MX: {
      OPERATOR_ID: 67,
      SITE_ID: 1014,
    },
    EMPELOTADOS_MX_IQ: {
      OPERATOR_ID: 65,
      CLIENT_ID: 4,
    },
    BET_WCS: {
      OPERATOR_ID: 55,
      SITE_ID: 1003,
    },
    HIPICA: {
      OPERATOR_ID: 58,
      SITE_ID: 885,
    },
    CARIBE_BETS: {
      OPERATOR_ID: 59,
      SITE_ID: 894,
    },
    PPH_WAGERING: {
      OPERATOR_ID: 60,
      SITE_ID: 996,
    },
    APUESTAS_BULLS: {
      OPERATOR_ID: 61,
      SITE_ID: 1007,
    },
    TU_APUESTA_365: {
      OPERATOR_ID: 57,
      SITE_ID: 995,
    },
    PAISANO_BET: {
      OPERATOR_ID: 62,
      SITE_ID: 1006,
    },
    BHR_AG: {
      OPERATOR_ID: 63,
      SITE_ID: 928,
    },
    BET_ODDS: {
      OPERATOR_ID: 66,
      SITE_ID: 407,
    },
    WIN_TECH_GS_COM: {
      OPERATOR_ID: 68,
      SITE_ID: 937,
    },
    WAGER_WIN_AG: {
      OPERATOR_ID: 69,
      SITE_ID: 925,
    },
    WINTORY_CASINO_COM: {
      OPERATOR_ID: 70,
      SITE_ID: 1021,
    },
    LA_ROCA_007_COM: {
      OPERATOR_ID: 71,
      SITE_ID: 1020,
    },
    SPORTS_KING_VIP: {
      OPERATOR_ID: 72,
      SITE_ID: 1022,
    },
    GALLO_PLAY_COM: {
      OPERATOR_ID: 73,
      SITE_ID: 1023,
    },
    AMEX_BET_MX: {
      OPERATOR_ID: 74,
      SITE_ID: 929,
    },
    DRAGON_88_COM: {
      OPERATOR_ID: 76,
      SITE_ID: 886,
    },
    DRAGON_88_VN_COM: {
      OPERATOR_ID: 77,
      SITE_ID: 887,
    },
    ACTION_365_AG: {
      OPERATOR_ID: 78,
      SITE_ID: 1028,
    },
    GAME_DAY_BET: {
      OPERATOR_ID: 79,
      SITE_ID: 1029,
    },
    ROOMS_365_VIP: {
      OPERATOR_ID: 80,
      SITE_ID: 1032,
    },
    BET_KINGS_888: {
      OPERATOR_ID: 83,
      SITE_ID: 1037,
    },
    TIEMPOS_DIGITALES_COM: {
      OPERATOR_ID: 84,
      SITE_ID: 1030,
    },
    HIGH_ROLLERS_IO: {
      OPERATOR_ID: 81,
      SITE_ID: 1033,
    },
    CRYPTO_BOOKIE_IO: {
      OPERATOR_ID: 82,
      SITE_ID: 1034,
    },
    VIP_CLUB_BET: {
      OPERATOR_ID: 85,
      SITE_ID: 1035,
    },
    BET_FINE_24: {
      OPERATOR_ID: 86,
      SITE_ID: 856,
    },
    I_GAMBLER_IO: {
      OPERATOR_ID: 87,
      SITE_ID: 1001,
    },
    APUESTA_PALACE_COM: {
      OPERATOR_ID: 90,
      SITE_ID: 1031,
    },
    GYM_AVENIDA_COM: {
      OPERATOR_ID: 92,
      SITE_ID: 1046,
    },
    GYM_365_COM: {
      OPERATOR_ID: 93,
      SITE_ID: 1048,
    },
    CASINO_BET_AG: {
      OPERATOR_ID: 89,
      SITE_ID: 1044,
    },
    BET_ALL_LIVE: {
      OPERATOR_ID: 88,
      SITE_ID: 1042,
    },
    BOOKIE_PRIME_FUN: {
      OPERATOR_ID: 91,
      SITE_ID: 1047,
    },
    ANGUILLA_BET_AI: {
      OPERATOR_ID: 94,
      SITE_ID: 905,
    },
    APUESTAS_GOLDEN_COM: {
      OPERATOR_ID: 96,
      SITE_ID: 1051,
    },
    APUESTAS_LA_CIMA_COM: {
      OPERATOR_ID: 97,
      SITE_ID: 1057,
    },
    APUESTAS_MARISEL_COM: {
      OPERATOR_ID: 98,
      SITE_ID: 1052,
    },
    APUESTAS_FOLIATTI_COM: {
      OPERATOR_ID: 95,
      SITE_ID: 1053,
    },
    BIG_BET_PA: {
      OPERATOR_ID: 102,
      SITE_ID: 867,
    },
    APUESTAS_VILLAS_PALACE_COM: {
      OPERATOR_ID: 99,
      SITE_ID: 1055,
    },
    APUESTAS_WINLAND_COM: {
      OPERATOR_ID: 100,
      SITE_ID: 1054,
    },
    APUESTAS_WINPOT_COM: {
      OPERATOR_ID: 101,
      SITE_ID: 1056,
    },
    PRIME_TIME_SPORTSBOOK: {
      OPERATOR_ID: 103,
      SITE_ID: 1062,
    },
    TRIPLE_BET_AG: {
      OPERATOR_ID: 104,
      SITE_ID: 1049,
    },
    BET_ALL_AG: {
      OPERATOR_ID: 105,
      SITE_ID: 1045,
    },
    MAYBACH_CASINO_COM: {
      OPERATOR_ID: 106,
      SITE_ID: 1065,
    },
    KAPITAL_COM: {
      OPERATOR_ID: 107,
      SITE_ID: 1063,
    },
    AC_NOSOTROS_COM: {
      OPERATOR_ID: 109,
      SITE_ID: 626,
    },
    STAR_SPORT_DO: {
      OPERATOR_ID: 108,
      SITE_ID: 774,
    },
    BETS_AMERICA_007_VIP: {
      OPERATOR_ID: 111,
      SITE_ID: 1071,
    },
    QUICK_CASH_AG: {
      OPERATOR_ID: 110,
      SITE_ID: 1070,
    },
    CASINO_GCCCT_COM: {
      OPERATOR_ID: 114,
      SITE_ID: 889,
    },
    BETS_SWING_COM: {
      OPERATOR_ID: 112,
      SITE_ID: 599,
    },
    EL_INMEJORABLE_COM: {
      OPERATOR_ID: 113,
      SITE_ID: 735,
    },
    SORTE_NET_BET: {
      OPERATOR_ID: 120,
      SITE_ID: 1064,
    },
    ALL_IN_SPORTBOOK: {
      OPERATOR_ID: 115,
      SITE_ID: 869,
    },
    WEST_COAST_SPORTBOOK: {
      OPERATOR_ID: 118,
      SITE_ID: 849,
    },
    TRACK_AND_RACES_COM: {
      OPERATOR_ID: 119,
      SITE_ID: 1027,
    },
    TRACK_AND_RACES_LIVE: {
      OPERATOR_ID: 119,
      SITE_ID: 1027,
    },
    TV_CLUB_SPORTS: {
      OPERATOR_ID: 117,
      SITE_ID: 831,
    },
    CLUB_CARIBBEAN_SPORTS: {
      OPERATOR_ID: 116,
      SITE_ID: 1025,
    },
    SORTE_NET_BET_LIVE: {
      OPERATOR_ID: 120,
      SITE_ID: 1064,
    },
    JUEGA_YAA_COM: {
      OPERATOR_ID: 121,
      SITE_ID: 1073,
    },
    REGAL_CASINO: {
      OPERATOR_ID: 122,
      SITE_ID: 908,
    },
    APUESTA_GOLD_COM: {
      OPERATOR_ID: 124,
      SITE_ID: 1072,
    },
    ORALE_CASINO_COM: {
      OPERATOR_ID: 123,
      SITE_ID: 1075,
    },
    GOLDEN_PLAY_COM: {
      OPERATOR_ID: 125,
      SITE_ID: 1078,
    },
    APUESTA_MEX_COM: {
      OPERATOR_ID: 127,
      SITE_ID: 670,
    },
    RED_ZONE_247_CLUB: {
      OPERATOR_ID: 128,
      SITE_ID: 1082,
    },
    BLACK_22_AG: {
      OPERATOR_ID: 132,
      SITE_ID: 320,
    },
    JAZZ_247_AG: {
      OPERATOR_ID: 133,
      SITE_ID: 100,
    },
    JAZZ_888_AG: {
      OPERATOR_ID: 134,
      SITE_ID: 101,
    },
    REAL_SPORTS_ODDS_COM: {
      OPERATOR_ID: 148,
      SITE_ID: 649,
    },
    FIVE_STAR_ACTION_COM: {
      OPERATOR_ID: 141,
      SITE_ID: 576,
    },
    ALL_ACTION_365_AG: {
      OPERATOR_ID: 139,
      SITE_ID: 583,
    },
    PLAY_JW_AG: {
      OPERATOR_ID: 136,
      SITE_ID: 282,
    },
    EMPIRE_BETS_COM: {
      OPERATOR_ID: 162,
      SITE_ID: 602,
    },
    WAGER_WIRE_NET: {
      OPERATOR_ID: 163,
      SITE_ID: 449,
    },
    DFW_COLLEGE_ONLINE: {
      OPERATOR_ID: 159,
      SITE_ID: 979,
    },
    PLAY_SPORTS_527_COM: {
      OPERATOR_ID: 156,
      SITE_ID: 266,
    },
    BEAT_THE_ODDS_AG: {
      OPERATOR_ID: 152,
      SITE_ID: 810,
    },
    PLAY_DAREDEVIL_COM: {
      OPERATOR_ID: 154,
      SITE_ID: 188,
    },
    WAGER_GIANT_COM: {
      OPERATOR_ID: 137,
      SITE_ID: 298,
    },
    ODDS_2_WAGER_COM: {
      OPERATOR_ID: 157,
      SITE_ID: 581,
    },
    SPORTS_FAN_MX_COM: {
      OPERATOR_ID: 155,
      SITE_ID: 70,
    },
    PICK2_SCORE_COM: {
      OPERATOR_ID: 149,
      SITE_ID: 480,
    },
    WAGERS_2x2_COM: {
      OPERATOR_ID: 140,
      SITE_ID: 563,
    },
    BET_VEGAS_527_COM: {
      OPERATOR_ID: 153,
      SITE_ID: 425,
    },
    BET_MY_LINES_COM: {
      OPERATOR_ID: 142,
      SITE_ID: 361,
    },
    PLAY_SPORTS_123_COM: {
      OPERATOR_ID: 145,
      SITE_ID: 256,
    },
    NS_SPORTS_CLUB_COM: {
      OPERATOR_ID: 138,
      SITE_ID: 109,
    },
    BETGAMEDAY_NET: {
      OPERATOR_ID: 147,
      SITE_ID: 564,
    },
    SPORTS_GANA_COM: {
      OPERATOR_ID: 151,
      SITE_ID: 834,
    },
    BET_AMERICA_MX: {
      OPERATOR_ID: 146,
      SITE_ID: 697,
    },
    XXX_BET_COM: {
      OPERATOR_ID: 158,
      SITE_ID: 115,
    },
    MJ_395_COM: {
      OPERATOR_ID: 160,
      SITE_ID: 367,
    },
    EBETS_247_COM: {
      OPERATOR_ID: 150,
      SITE_ID: 83,
    },
    FAT_MOE_2G_COM: {
      OPERATOR_ID: 161,
      SITE_ID: 199,
    },
    EL_OTRO_CANAL_COM: {
      OPERATOR_ID: 165,
      SITE_ID: 1109,
    },
    BROADWAY_SPORTS_MX: {
      OPERATOR_ID: 131,
      SITE_ID: 1088,
    },
    CASTRO_SPORT_COM: {
      OPERATOR_ID: 164,
      SITE_ID: 1087,
    },
    COLOMBO_BET: {
      OPERATOR_ID: 130,
      SITE_ID: 938,
    },
    BUDA_BET_COM: {
      OPERATOR_ID: 170,
      SITE_ID: 274,
    },
    PAY_DAY_GAMES: {
      OPERATOR_ID: 167,
      SITE_ID: 1115,
    },
    JC_SPORTS_BET_COM: {
      OPERATOR_ID: 171,
      SITE_ID: 118,
    },
    RED_107_NET: {
      OPERATOR_ID: 166,
      SITE_ID: 948,
    },
    LUCKY_BETS_21_COM: {
      OPERATOR_ID: 129,
      SITE_ID: 1086,
    },
    GALLERY_V_88_COM: {
      OPERATOR_ID: 169,
      SITE_ID: 505,
    },
  },
  DEMO_USER: "testlobby",
  production: false,
};
