import { environment } from "src/environments/environment";
import { casinoClient } from "../enums/casinoClient.enum";
import {
  customNavData,
  defaultNavData,
  navSimpleData,
} from "@shared/layout/nav/data/nav.data";

import { betamaNavData } from "@shared/layout/nav/data/betama-nav.data";
import { iBetSportNavData } from "@shared/layout/nav/data/i-bet-sports-nav.data";

export const CasinoClient = {
  //////////////////////
  // DEFAULT CLIENTS //
  ////////////////////
  [casinoClient.JAZZ]: {
    class: "client-jazz",
    operatorId: environment.CLIENTS.JAZZ.OPERATOR_ID,
    siteID: environment.CLIENTS.JAZZ.SITE_ID,
    name: casinoClient.JAZZ,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.JAZZ_SPORTS]: {
    class: "client-jazz-sports",
    operatorId: environment.CLIENTS.JAZZ_SPORTS.OPERATOR_ID,
    siteID: environment.CLIENTS.JAZZ_SPORTS.SITE_ID,
    name: casinoClient.JAZZ_SPORTS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.TICO_BETS]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.TICO_BETS.OPERATOR_ID,
    siteID: environment.CLIENTS.TICO_BETS.SITE_ID,
    name: casinoClient.TICO_BETS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.ABC_ISLANDS]: {
    class: "client-abc-islands",
    operatorId: environment.CLIENTS.ABC_ISLANDS.OPERATOR_ID,
    siteID: environment.CLIENTS.ABC_ISLANDS.SITE_ID,
    name: casinoClient.ABC_ISLANDS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.COCK_FIGHTS]: {
    class: "client-cock-fights",
    operatorId: environment.CLIENTS.COCK_FIGHTS.OPERATOR_ID,
    siteID: environment.CLIENTS.COCK_FIGHTS.SITE_ID,
    name: casinoClient.COCK_FIGHTS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.LOOSE_LINES]: {
    class: "client-loose-lines",
    operatorId: environment.CLIENTS.LOOSE_LINES.OPERATOR_ID,
    siteID: environment.CLIENTS.LOOSE_LINES.SITE_ID,
    name: casinoClient.LOOSE_LINES,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BET_CRYPTO]: {
    class: "client-bet-crypto",
    operatorId: environment.CLIENTS.BET_CRYPTO.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_CRYPTO.SITE_ID,
    name: casinoClient.BET_CRYPTO,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.GG_BETS]: {
    class: "client-gg-bets",
    operatorId: environment.CLIENTS.GG_BETS.OPERATOR_ID,
    siteID: environment.CLIENTS.GG_BETS.SITE_ID,
    name: casinoClient.GG_BETS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.VIP_SPORTSBOOK]: {
    class: "client-vip-sportsbook",
    operatorId: environment.CLIENTS.VIP_SPORTSBOOK.OPERATOR_ID,
    siteID: environment.CLIENTS.VIP_SPORTSBOOK.SITE_ID,
    name: casinoClient.VIP_SPORTSBOOK,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BOSS_BET]: {
    class: "client-boss-bets",
    operatorId: environment.CLIENTS.BOSS_BET.OPERATOR_ID,
    siteID: environment.CLIENTS.BOSS_BET.SITE_ID,
    name: casinoClient.BOSS_BET,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.I_BET_SPORTS_COM]: {
    class: "client-i-bet-sports",
    operatorId: environment.CLIENTS.I_BET_SPORTS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.I_BET_SPORTS_COM.SITE_ID,
    name: casinoClient.I_BET_SPORTS_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.YO_GANO]: {
    class: "client-yo-gano",
    operatorId: environment.CLIENTS.YO_GANO.OPERATOR_ID,
    siteID: environment.CLIENTS.YO_GANO.SITE_ID,
    name: casinoClient.YO_GANO,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BET_VIP_MX]: {
    class: "client-bet-vip-mx",
    operatorId: environment.CLIENTS.BET_VIP_MX.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_VIP_MX.SITE_ID,
    name: casinoClient.BET_VIP_MX,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hideCancelButton: true,
      },
    },
  },
  [casinoClient.MR_REELS]: {
    class: "client-mr-reels",
    operatorId: environment.CLIENTS.MR_REELS.OPERATOR_ID,
    siteID: environment.CLIENTS.MR_REELS.SITE_ID,
    name: casinoClient.MR_REELS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BE_BET_AG]: {
    class: "client-be-bet",
    operatorId: environment.CLIENTS.BE_BET_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.BE_BET_AG.SITE_ID,
    name: casinoClient.BE_BET_AG,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.JET_BET]: {
    class: "client-jet-bet",
    operatorId: environment.CLIENTS.JET_BET.OPERATOR_ID,
    siteID: environment.CLIENTS.JET_BET.SITE_ID,
    name: casinoClient.JET_BET,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.HIGH_ROLLERS_AG]: {
    class: "client-high-rollers-ag",
    operatorId: environment.CLIENTS.HIGH_ROLLERS_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.HIGH_ROLLERS_AG.SITE_ID,
    name: casinoClient.HIGH_ROLLERS_AG,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BOOKIE_PRIME]: {
    class: "client-bookie-prime",
    operatorId: environment.CLIENTS.BOOKIE_PRIME.OPERATOR_ID,
    siteID: environment.CLIENTS.BOOKIE_PRIME.SITE_ID,
    name: casinoClient.BOOKIE_PRIME,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BETS_AMERICA_007]: {
    class: "client-bets-america-007",
    operatorId: environment.CLIENTS.BETS_AMERICA_007.OPERATOR_ID,
    siteID: environment.CLIENTS.BETS_AMERICA_007.SITE_ID,
    name: casinoClient.BETS_AMERICA_007,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.JUGA_EN_CASA]: {
    class: "client-juega-en-casa",
    operatorId: environment.CLIENTS.JUGA_EN_CASA.OPERATOR_ID,
    siteID: environment.CLIENTS.JUGA_EN_CASA.SITE_ID,
    name: casinoClient.JUGA_EN_CASA,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EMPELOTADOS_COM]: {
    class: "client-empelotados-com",
    operatorId: environment.CLIENTS.EMPELOTADOS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.EMPELOTADOS_COM.SITE_ID,
    name: casinoClient.EMPELOTADOS_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EMPELOTADOS_MX]: {
    class: "client-empelotados-mx",
    operatorId: environment.CLIENTS.EMPELOTADOS_MX.OPERATOR_ID,
    siteID: environment.CLIENTS.EMPELOTADOS_MX.SITE_ID,
    name: casinoClient.EMPELOTADOS_MX,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EMPELOTADOS_MX_IQ]: {
    class: "client-empelotados-mx",
    operatorId: environment.CLIENTS.EMPELOTADOS_MX_IQ.OPERATOR_ID,
    name: casinoClient.EMPELOTADOS_MX_IQ,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.CARIBE_BETS]: {
    class: "client-caribe-bets",
    operatorId: environment.CLIENTS.CARIBE_BETS.OPERATOR_ID,
    siteID: environment.CLIENTS.CARIBE_BETS.SITE_ID,
    name: casinoClient.CARIBE_BETS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.HIPICA]: {
    class: "client-hipica-la",
    operatorId: environment.CLIENTS.HIPICA.OPERATOR_ID,
    siteID: environment.CLIENTS.HIPICA.SITE_ID,
    name: casinoClient.HIPICA,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTAS_BULLS]: {
    class: "client-apuestas-bulls",
    operatorId: environment.CLIENTS.APUESTAS_BULLS.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTAS_BULLS.SITE_ID,
    name: casinoClient.APUESTAS_BULLS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.TU_APUESTA_365]: {
    class: "client-tu-apuesta-365",
    operatorId: environment.CLIENTS.TU_APUESTA_365.OPERATOR_ID,
    siteID: environment.CLIENTS.TU_APUESTA_365.SITE_ID,
    name: casinoClient.TU_APUESTA_365,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PAISANO_BET]: {
    class: "client-paisano-bet",
    operatorId: environment.CLIENTS.PAISANO_BET.OPERATOR_ID,
    siteID: environment.CLIENTS.PAISANO_BET.SITE_ID,
    name: casinoClient.PAISANO_BET,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BHR_AG]: {
    class: "client-bhr-ag",
    operatorId: environment.CLIENTS.BHR_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.BHR_AG.SITE_ID,
    name: casinoClient.BHR_AG,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BET_ODDS]: {
    class: "client-bet-odds",
    operatorId: environment.CLIENTS.BET_ODDS.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_ODDS.SITE_ID,
    name: casinoClient.BET_ODDS,
    blocks: [],
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WIN_TECH_GS_COM]: {
    class: "client-win-tech-gs-com",
    operatorId: environment.CLIENTS.WIN_TECH_GS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.WIN_TECH_GS_COM.SITE_ID,
    name: casinoClient.WIN_TECH_GS_COM,
    blocks: [],
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WAGER_WIN_AG]: {
    class: "client-wager-win-ag",
    operatorId: environment.CLIENTS.WAGER_WIN_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_WIN_AG.SITE_ID,
    name: casinoClient.WAGER_WIN_AG,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WINTORY_CASINO_COM]: {
    class: "client-wintory-casino-com",
    operatorId: environment.CLIENTS.WINTORY_CASINO_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.WINTORY_CASINO_COM.SITE_ID,
    name: casinoClient.WINTORY_CASINO_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.SPORTS_KING_VIP]: {
    class: "client-sports-king-vip",
    operatorId: environment.CLIENTS.SPORTS_KING_VIP.OPERATOR_ID,
    siteID: environment.CLIENTS.SPORTS_KING_VIP.SITE_ID,
    name: casinoClient.SPORTS_KING_VIP,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.GALLO_PLAY_COM]: {
    class: "client-bet-vip-mx",
    operatorId: environment.CLIENTS.GALLO_PLAY_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.GALLO_PLAY_COM.SITE_ID,
    name: casinoClient.GALLO_PLAY_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.AMEX_BET_MX]: {
    class: "client-amex-bet-mx",
    operatorId: environment.CLIENTS.AMEX_BET_MX.OPERATOR_ID,
    siteID: environment.CLIENTS.AMEX_BET_MX.SITE_ID,
    name: casinoClient.AMEX_BET_MX,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.DRAGON_88_COM]: {
    class: "client-dragon-88-com",
    operatorId: environment.CLIENTS.DRAGON_88_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.DRAGON_88_COM.SITE_ID,
    name: casinoClient.DRAGON_88_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.DRAGON_88_VN_COM]: {
    class: "client-dragon-88-com",
    operatorId: environment.CLIENTS.DRAGON_88_VN_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.DRAGON_88_VN_COM.SITE_ID,
    name: casinoClient.DRAGON_88_VN_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.ROOMS_365_VIP]: {
    class: "client-be-bet",
    operatorId: environment.CLIENTS.ROOMS_365_VIP.OPERATOR_ID,
    siteID: environment.CLIENTS.ROOMS_365_VIP.SITE_ID,
    name: casinoClient.ROOMS_365_VIP,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.TIEMPOS_DIGITALES_COM]: {
    class: "client-tiempos-digitales-com",
    operatorId: environment.CLIENTS.TIEMPOS_DIGITALES_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.TIEMPOS_DIGITALES_COM.SITE_ID,
    name: casinoClient.TIEMPOS_DIGITALES_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.HIGH_ROLLERS_IO]: {
    class: "client-high-rollers-io",
    operatorId: environment.CLIENTS.HIGH_ROLLERS_IO.OPERATOR_ID,
    siteID: environment.CLIENTS.HIGH_ROLLERS_IO.SITE_ID,
    name: casinoClient.HIGH_ROLLERS_IO,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.CRYPTO_BOOKIE_IO]: {
    class: "client-crypto-bookie-io",
    operatorId: environment.CLIENTS.CRYPTO_BOOKIE_IO.OPERATOR_ID,
    siteID: environment.CLIENTS.CRYPTO_BOOKIE_IO.SITE_ID,
    name: casinoClient.CRYPTO_BOOKIE_IO,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.VIP_CLUB_BET]: {
    class: "client-jazz-sports",
    operatorId: environment.CLIENTS.VIP_CLUB_BET.OPERATOR_ID,
    siteID: environment.CLIENTS.VIP_CLUB_BET.SITE_ID,
    name: casinoClient.VIP_CLUB_BET,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BET_FINE_24]: {
    class: "client-bet-fine-24",
    operatorId: environment.CLIENTS.BET_FINE_24.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_FINE_24.SITE_ID,
    name: casinoClient.BET_FINE_24,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.I_GAMBLER_IO]: {
    class: "client-i-gambler-io",
    operatorId: environment.CLIENTS.I_GAMBLER_IO.OPERATOR_ID,
    siteID: environment.CLIENTS.I_GAMBLER_IO.SITE_ID,
    name: casinoClient.I_GAMBLER_IO,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.APUESTA_PALACE_COM]: {
    class: "client-apuesta-palace-com",
    operatorId: environment.CLIENTS.APUESTA_PALACE_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTA_PALACE_COM.SITE_ID,
    name: casinoClient.APUESTA_PALACE_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.GYM_AVENIDA_COM]: {
    class: "client-bets-america-007",
    operatorId: environment.CLIENTS.GYM_AVENIDA_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.GYM_AVENIDA_COM.SITE_ID,
    name: casinoClient.GYM_AVENIDA_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.GYM_365_COM]: {
    class: "client-tu-apuesta-365",
    operatorId: environment.CLIENTS.GYM_365_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.GYM_365_COM.SITE_ID,
    name: casinoClient.GYM_365_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.ANGUILLA_BET_AI]: {
    class: "client-anguilla-bet-ai",
    operatorId: environment.CLIENTS.ANGUILLA_BET_AI.OPERATOR_ID,
    siteID: environment.CLIENTS.ANGUILLA_BET_AI.SITE_ID,
    name: casinoClient.ANGUILLA_BET_AI,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTAS_GOLDEN_COM]: {
    class: "client-apuesta-palace-com",
    operatorId: environment.CLIENTS.APUESTAS_GOLDEN_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTAS_GOLDEN_COM.SITE_ID,
    name: casinoClient.APUESTAS_GOLDEN_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTAS_LA_CIMA_COM]: {
    class: "client-apuestas-la-cima-com",
    operatorId: environment.CLIENTS.APUESTAS_LA_CIMA_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTAS_LA_CIMA_COM.SITE_ID,
    name: casinoClient.APUESTAS_LA_CIMA_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTAS_MARISEL_COM]: {
    class: "client-apuesta-palace-com",
    operatorId: environment.CLIENTS.APUESTAS_MARISEL_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTAS_MARISEL_COM.SITE_ID,
    name: casinoClient.APUESTAS_MARISEL_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTAS_FOLIATTI_COM]: {
    class: "client-apuestas-foliatti-com",
    operatorId: environment.CLIENTS.APUESTAS_FOLIATTI_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTAS_FOLIATTI_COM.SITE_ID,
    name: casinoClient.APUESTAS_FOLIATTI_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BIG_BET_PA]: {
    class: "client-bigbet-pa",
    operatorId: environment.CLIENTS.BIG_BET_PA.OPERATOR_ID,
    siteID: environment.CLIENTS.BIG_BET_PA.SITE_ID,
    name: casinoClient.BIG_BET_PA,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTAS_VILLAS_PALACE_COM]: {
    class: "client-apuestas-foliatti-com",
    operatorId: environment.CLIENTS.APUESTAS_VILLAS_PALACE_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTAS_VILLAS_PALACE_COM.SITE_ID,
    name: casinoClient.APUESTAS_VILLAS_PALACE_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTAS_WINLAND_COM]: {
    class: "client-apuestas-winland-com",
    operatorId: environment.CLIENTS.APUESTAS_WINLAND_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTAS_WINLAND_COM.SITE_ID,
    name: casinoClient.APUESTAS_WINLAND_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTAS_WINPOT_COM]: {
    class: "client-apuestas-winpot-com",
    operatorId: environment.CLIENTS.APUESTAS_WINPOT_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTAS_WINPOT_COM.SITE_ID,
    name: casinoClient.APUESTAS_WINPOT_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      theme: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.TRIPLE_BET_AG]: {
    class: "client-triple-bet-ag",
    operatorId: environment.CLIENTS.TRIPLE_BET_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.TRIPLE_BET_AG.SITE_ID,
    name: casinoClient.TRIPLE_BET_AG,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      theme: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.MAYBACH_CASINO_COM]: {
    class: "client-maybach-casino",
    operatorId: environment.CLIENTS.MAYBACH_CASINO_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.MAYBACH_CASINO_COM.SITE_ID,
    name: casinoClient.MAYBACH_CASINO_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      theme: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.KAPITAL_COM]: {
    class: "kapital-com",
    operatorId: environment.CLIENTS.KAPITAL_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.KAPITAL_COM.SITE_ID,
    name: casinoClient.KAPITAL_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      theme: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.AC_NOSOTROS_COM]: {
    class: "client-ac-nosotros",
    operatorId: environment.CLIENTS.AC_NOSOTROS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.AC_NOSOTROS_COM.SITE_ID,
    name: casinoClient.AC_NOSOTROS_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      theme: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.STAR_SPORT_DO]: {
    class: "client-star-sport-do",
    operatorId: environment.CLIENTS.STAR_SPORT_DO.OPERATOR_ID,
    siteID: environment.CLIENTS.STAR_SPORT_DO.SITE_ID,
    name: casinoClient.STAR_SPORT_DO,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BETS_AMERICA_007_VIP]: {
    class: "client-bets-america-007",
    operatorId: environment.CLIENTS.BETS_AMERICA_007_VIP.OPERATOR_ID,
    siteID: environment.CLIENTS.BETS_AMERICA_007_VIP.SITE_ID,
    name: casinoClient.BETS_AMERICA_007_VIP,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.CASINO_GCCCT_COM]: {
    class: "client-golden-play-com",
    operatorId: environment.CLIENTS.CASINO_GCCCT_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.CASINO_GCCCT_COM.SITE_ID,
    name: casinoClient.CASINO_GCCCT_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BETS_SWING_COM]: {
    class: "client-bet-swing-com",
    operatorId: environment.CLIENTS.BETS_SWING_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.BETS_SWING_COM.SITE_ID,
    name: casinoClient.BETS_SWING_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EL_INMEJORABLE_COM]: {
    class: "client-el-inmejorable-com",
    operatorId: environment.CLIENTS.EL_INMEJORABLE_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.EL_INMEJORABLE_COM.SITE_ID,
    name: casinoClient.EL_INMEJORABLE_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.SORTE_NET_BET]: {
    class: "client-sorte-net-com",
    operatorId: environment.CLIENTS.SORTE_NET_BET.OPERATOR_ID,
    siteID: environment.CLIENTS.SORTE_NET_BET.SITE_ID,
    name: casinoClient.SORTE_NET_BET,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.CLUB_CARIBBEAN_SPORTS]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.CLUB_CARIBBEAN_SPORTS.OPERATOR_ID,
    siteID: environment.CLIENTS.CLUB_CARIBBEAN_SPORTS.SITE_ID,
    name: casinoClient.CLUB_CARIBBEAN_SPORTS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.TV_CLUB_SPORTS]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.TV_CLUB_SPORTS.OPERATOR_ID,
    siteID: environment.CLIENTS.TV_CLUB_SPORTS.SITE_ID,
    name: casinoClient.TV_CLUB_SPORTS,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.TRACK_AND_RACES_COM]: {
    class: "client-track-and-races",
    operatorId: environment.CLIENTS.TRACK_AND_RACES_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.TRACK_AND_RACES_COM.SITE_ID,
    name: casinoClient.TRACK_AND_RACES_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WEST_COAST_SPORTBOOK]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.WEST_COAST_SPORTBOOK.OPERATOR_ID,
    siteID: environment.CLIENTS.WEST_COAST_SPORTBOOK.SITE_ID,
    name: casinoClient.WEST_COAST_SPORTBOOK,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.ALL_IN_SPORTBOOK]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.ALL_IN_SPORTBOOK.OPERATOR_ID,
    siteID: environment.CLIENTS.ALL_IN_SPORTBOOK.SITE_ID,
    name: casinoClient.ALL_IN_SPORTBOOK,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.JUEGA_YAA_COM]: {
    class: "client-juega-yaa",
    operatorId: environment.CLIENTS.JUEGA_YAA_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.JUEGA_YAA_COM.SITE_ID,
    name: casinoClient.JUEGA_YAA_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.APUESTA_GOLD_COM]: {
    class: "client-apuesta-gold",
    operatorId: environment.CLIENTS.APUESTA_GOLD_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTA_GOLD_COM.SITE_ID,
    name: casinoClient.APUESTA_GOLD_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.ORALE_CASINO_COM]: {
    class: "client-orale-casino",
    operatorId: environment.CLIENTS.ORALE_CASINO_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.ORALE_CASINO_COM.SITE_ID,
    name: casinoClient.ORALE_CASINO_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.GOLDEN_PLAY_COM]: {
    class: "client-golden-play-com",
    operatorId: environment.CLIENTS.GOLDEN_PLAY_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.GOLDEN_PLAY_COM.SITE_ID,
    name: casinoClient.GOLDEN_PLAY_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTA_MEX_COM]: {
    class: "client-apuesta-mex",
    operatorId: environment.CLIENTS.APUESTA_MEX_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTA_MEX_COM.SITE_ID,
    name: casinoClient.APUESTA_MEX_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.SPORTS_GANA_COM]: {
    class: "client-sports-gana-com",
    operatorId: environment.CLIENTS.SPORTS_GANA_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.SPORTS_GANA_COM.SITE_ID,
    name: casinoClient.SPORTS_GANA_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BET_AMERICA_MX]: {
    class: "client-bigbet-pa",
    operatorId: environment.CLIENTS.BET_AMERICA_MX.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_AMERICA_MX.SITE_ID,
    name: casinoClient.BET_AMERICA_MX,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BROADWAY_SPORTS_MX]: {
    class: "client-broadway-sports-mx",
    operatorId: environment.CLIENTS.BROADWAY_SPORTS_MX.OPERATOR_ID,
    siteID: environment.CLIENTS.BROADWAY_SPORTS_MX.SITE_ID,
    name: casinoClient.BROADWAY_SPORTS_MX,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.LA_ROCA_007_COM]: {
    class: "client-la-roca-007",
    operatorId: environment.CLIENTS.LA_ROCA_007_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.LA_ROCA_007_COM.SITE_ID,
    name: casinoClient.LA_ROCA_007_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.CASTRO_SPORT_COM]: {
    class: "client-castro-sport",
    operatorId: environment.CLIENTS.CASTRO_SPORT_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.CASTRO_SPORT_COM.SITE_ID,
    name: casinoClient.CASTRO_SPORT_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.VIPER_LINES]: {
    class: "client-viper-lines",
    operatorId: environment.CLIENTS.VIPER_LINES.OPERATOR_ID,
    siteID: environment.CLIENTS.VIPER_LINES.SITE_ID,
    name: casinoClient.VIPER_LINES,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.COLOMBO_BET]: {
    class: "client-colombo-bet",
    operatorId: environment.CLIENTS.COLOMBO_BET.OPERATOR_ID,
    siteID: environment.CLIENTS.COLOMBO_BET.SITE_ID,
    name: casinoClient.COLOMBO_BET,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PAY_DAY_GAMES]: {
    class: "client-pay-day",
    operatorId: environment.CLIENTS.PAY_DAY_GAMES.OPERATOR_ID,
    siteID: environment.CLIENTS.PAY_DAY_GAMES.SITE_ID,
    name: casinoClient.PAY_DAY_GAMES,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },

  //////////////////////
  // CUSTOMS CLIENTS //
  ////////////////////
  [casinoClient.BETAMA]: {
    class: "client-betama",
    operatorId: environment.CLIENTS.BETAMA.OPERATOR_ID,
    siteID: environment.CLIENTS.BETAMA.SITE_ID,
    name: casinoClient.BETAMA,
    menu: betamaNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      theme: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.I_BET_PIX]: {
    class: "client-i-bet-pix",
    operatorId: environment.CLIENTS.I_BET_PIX.OPERATOR_ID,
    siteID: environment.CLIENTS.I_BET_PIX.SITE_ID,
    name: casinoClient.I_BET_PIX,
    menu: customNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.I_BET_SPORTS_AG]: {
    class: "client-i-bet-sports",
    operatorId: environment.CLIENTS.I_BET_SPORTS_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.I_BET_SPORTS_AG.SITE_ID,
    name: casinoClient.I_BET_SPORTS_AG,
    menu: iBetSportNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PRIME_TIME_SPORTSBOOK]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PRIME_TIME_SPORTSBOOK.OPERATOR_ID,
    siteID: environment.CLIENTS.PRIME_TIME_SPORTSBOOK.SITE_ID,
    name: casinoClient.PRIME_TIME_SPORTSBOOK,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      hideProvider: true,
      theme: [],
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BET_ALL_AG]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.BET_ALL_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_ALL_AG.SITE_ID,
    name: casinoClient.BET_ALL_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      theme: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.QUICK_CASH_AG]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.QUICK_CASH_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.QUICK_CASH_AG.SITE_ID,
    name: casinoClient.QUICK_CASH_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.RED_ZONE_247_CLUB]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.RED_ZONE_247_CLUB.OPERATOR_ID,
    siteID: environment.CLIENTS.RED_ZONE_247_CLUB.SITE_ID,
    name: casinoClient.RED_ZONE_247_CLUB,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.JAZZ_888_AG]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.JAZZ_888_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.JAZZ_888_AG.SITE_ID,
    name: casinoClient.JAZZ_888_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.JAZZ_247_AG]: {
    class: "client-jazz-247-ag",
    operatorId: environment.CLIENTS.JAZZ_247_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.JAZZ_247_AG.SITE_ID,
    name: casinoClient.JAZZ_247_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BLACK_22_AG]: {
    class: "client-bigbet-pa",
    operatorId: environment.CLIENTS.BLACK_22_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.BLACK_22_AG.SITE_ID,
    name: casinoClient.BLACK_22_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.REAL_SPORTS_ODDS_COM]: {
    class: "client-real-sports-odds-com",
    operatorId: environment.CLIENTS.REAL_SPORTS_ODDS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.REAL_SPORTS_ODDS_COM.SITE_ID,
    name: casinoClient.REAL_SPORTS_ODDS_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.FIVE_STAR_ACTION_COM]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.FIVE_STAR_ACTION_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.FIVE_STAR_ACTION_COM.SITE_ID,
    name: casinoClient.FIVE_STAR_ACTION_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.ALL_ACTION_365_AG]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.ALL_ACTION_365_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.ALL_ACTION_365_AG.SITE_ID,
    name: casinoClient.ALL_ACTION_365_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PLAY_JW_AG]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PLAY_JW_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_JW_AG.SITE_ID,
    name: casinoClient.PLAY_JW_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EMPIRE_BETS_COM]: {
    class: "client-empire-bets-com",
    operatorId: environment.CLIENTS.EMPIRE_BETS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.EMPIRE_BETS_COM.SITE_ID,
    name: casinoClient.EMPIRE_BETS_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WAGER_WIRE_NET]: {
    class: "client-wager-wire-net",
    operatorId: environment.CLIENTS.WAGER_WIRE_NET.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_WIRE_NET.SITE_ID,
    name: casinoClient.WAGER_WIRE_NET,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.DFW_COLLEGE_ONLINE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.DFW_COLLEGE_ONLINE.OPERATOR_ID,
    siteID: environment.CLIENTS.DFW_COLLEGE_ONLINE.SITE_ID,
    name: casinoClient.DFW_COLLEGE_ONLINE,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PLAY_SPORTS_527_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PLAY_SPORTS_527_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_SPORTS_527_COM.SITE_ID,
    name: casinoClient.PLAY_SPORTS_527_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BEAT_THE_ODDS_AG]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BEAT_THE_ODDS_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.BEAT_THE_ODDS_AG.SITE_ID,
    name: casinoClient.BEAT_THE_ODDS_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PLAY_DAREDEVIL_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PLAY_DAREDEVIL_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_DAREDEVIL_COM.SITE_ID,
    name: casinoClient.PLAY_DAREDEVIL_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WAGER_GIANT_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.WAGER_GIANT_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_GIANT_COM.SITE_ID,
    name: casinoClient.WAGER_GIANT_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.SPORTS_FAN_MX_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.SPORTS_FAN_MX_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.SPORTS_FAN_MX_COM.SITE_ID,
    name: casinoClient.SPORTS_FAN_MX_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PICK2_SCORE_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PICK2_SCORE_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.PICK2_SCORE_COM.SITE_ID,
    name: casinoClient.PICK2_SCORE_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WAGERS_2x2_COM]: {
    class: "client-wagers-2x2",
    operatorId: environment.CLIENTS.WAGERS_2x2_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGERS_2x2_COM.SITE_ID,
    name: casinoClient.WAGERS_2x2_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BET_VEGAS_527_COM]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.BET_VEGAS_527_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_VEGAS_527_COM.SITE_ID,
    name: casinoClient.BET_VEGAS_527_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BET_MY_LINES_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BET_MY_LINES_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_MY_LINES_COM.SITE_ID,
    name: casinoClient.BET_MY_LINES_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PLAY_SPORTS_123_COM]: {
    class: "client-play-sports-123-com",
    operatorId: environment.CLIENTS.PLAY_SPORTS_123_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_SPORTS_123_COM.SITE_ID,
    name: casinoClient.PLAY_SPORTS_123_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.NS_SPORTS_CLUB_COM]: {
    class: "client-ns-sports-club-com",
    operatorId: environment.CLIENTS.NS_SPORTS_CLUB_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.NS_SPORTS_CLUB_COM.SITE_ID,
    name: casinoClient.NS_SPORTS_CLUB_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BETGAMEDAY_NET]: {
    class: "client-betgameday-net",
    operatorId: environment.CLIENTS.BETGAMEDAY_NET.OPERATOR_ID,
    siteID: environment.CLIENTS.BETGAMEDAY_NET.SITE_ID,
    name: casinoClient.BETGAMEDAY_NET,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.XXX_BET_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.XXX_BET_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.XXX_BET_COM.SITE_ID,
    name: casinoClient.XXX_BET_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.MJ_395_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.MJ_395_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.MJ_395_COM.SITE_ID,
    name: casinoClient.MJ_395_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EBETS_247_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.EBETS_247_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.EBETS_247_COM.SITE_ID,
    name: casinoClient.EBETS_247_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.ODDS_2_WAGER_COM]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.ODDS_2_WAGER_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.ODDS_2_WAGER_COM.SITE_ID,
    name: casinoClient.ODDS_2_WAGER_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.FAT_MOE_2G_COM]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.FAT_MOE_2G_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.FAT_MOE_2G_COM.SITE_ID,
    name: casinoClient.FAT_MOE_2G_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EL_OTRO_CANAL_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.EL_OTRO_CANAL_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.EL_OTRO_CANAL_COM.SITE_ID,
    name: casinoClient.EL_OTRO_CANAL_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BUDA_BET_COM]: {
    class: "client-buda-bet-com",
    operatorId: environment.CLIENTS.BUDA_BET_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.BUDA_BET_COM.SITE_ID,
    name: casinoClient.BUDA_BET_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.JC_SPORTS_BET_COM]: {
    class: "client-jc-sports-bet-com",
    operatorId: environment.CLIENTS.JC_SPORTS_BET_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.JC_SPORTS_BET_COM.SITE_ID,
    name: casinoClient.JC_SPORTS_BET_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },

  ///////////////////
  // LIVE CLIENTS //
  //////////////////
  [casinoClient.BETAMA_LIVE]: {
    class: "client-betama",
    operatorId: environment.CLIENTS.BETAMA.OPERATOR_ID,
    siteID: environment.CLIENTS.BETAMA.SITE_ID,
    name: casinoClient.BETAMA_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },
  [casinoClient.I_BET_PIX_LIVE]: {
    class: "client-i-bet-pix",
    operatorId: environment.CLIENTS.I_BET_PIX.OPERATOR_ID,
    siteID: environment.CLIENTS.I_BET_PIX.SITE_ID,
    name: casinoClient.I_BET_PIX_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },
  [casinoClient.MR_REELS_LIVE]: {
    class: "client-mr-reels",
    operatorId: environment.CLIENTS.MR_REELS.OPERATOR_ID,
    siteID: environment.CLIENTS.MR_REELS.SITE_ID,
    name: casinoClient.MR_REELS_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },
  [casinoClient.BET_VIP_MX_LIVE]: {
    class: "client-bet-vip-mx",
    operatorId: environment.CLIENTS.BET_VIP_MX.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_VIP_MX.SITE_ID,
    name: casinoClient.BET_VIP_MX_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hideCancelButton: true,
      },
    },
  },
  [casinoClient.EMPELOTADOS_LIVE_MX]: {
    class: "client-empelotados-mx",
    operatorId: environment.CLIENTS.EMPELOTADOS_MX.OPERATOR_ID,
    siteID: environment.CLIENTS.EMPELOTADOS_MX.SITE_ID,
    name: casinoClient.EMPELOTADOS_MX,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.EMPELOTADOS_LIVE_MX_IQ]: {
    class: "client-empelotados-mx",
    operatorId: environment.CLIENTS.EMPELOTADOS_MX_IQ.OPERATOR_ID,
    name: casinoClient.EMPELOTADOS_MX_IQ,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.JUGA_EN_CASA_LIVE]: {
    class: "client-juega-en-casa",
    operatorId: environment.CLIENTS.JUGA_EN_CASA.OPERATOR_ID,
    siteID: environment.CLIENTS.JUGA_EN_CASA.SITE_ID,
    name: casinoClient.JUGA_EN_CASA_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },
  [casinoClient.WIN_TECH_GS_COM_LIVE]: {
    class: "client-win-tech-gs-com",
    operatorId: environment.CLIENTS.WIN_TECH_GS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.WIN_TECH_GS_COM.SITE_ID,
    name: casinoClient.WIN_TECH_GS_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },
  [casinoClient.GALLO_PLAY_COM_LIVE]: {
    class: "client-bet-vip-mx",
    operatorId: environment.CLIENTS.WIN_TECH_GS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.WIN_TECH_GS_COM.SITE_ID,
    name: casinoClient.WIN_TECH_GS_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },
  [casinoClient.TRACK_AND_RACES_LIVE]: {
    class: "client-track-and-races",
    operatorId: environment.CLIENTS.TRACK_AND_RACES_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.TRACK_AND_RACES_COM.SITE_ID,
    name: casinoClient.TRACK_AND_RACES_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
    },
  },
  [casinoClient.SORTE_NET_BET_LIVE]: {
    class: "client-sorte-net-com",
    operatorId: environment.CLIENTS.SORTE_NET_BET.OPERATOR_ID,
    siteID: environment.CLIENTS.SORTE_NET_BET.SITE_ID,
    name: casinoClient.SORTE_NET_BET_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },
  [casinoClient.JUEGA_YAA_LIVE]: {
    class: "client-juega-yaa",
    operatorId: environment.CLIENTS.JUEGA_YAA_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.JUEGA_YAA_COM.SITE_ID,
    name: casinoClient.JUEGA_YAA_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
    },
  },
  [casinoClient.REGAL_CASINO]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.REGAL_CASINO.OPERATOR_ID,
    siteID: environment.CLIENTS.REGAL_CASINO.SITE_ID,
    name: casinoClient.REGAL_CASINO,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTA_GOLD_LIVE]: {
    class: "client-apuesta-gold",
    operatorId: environment.CLIENTS.APUESTA_GOLD_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTA_GOLD_COM.SITE_ID,
    name: casinoClient.APUESTA_GOLD_LIVE,
    menu: navSimpleData,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.ORALE_CASINO_LIVE]: {
    class: "client-orale-casino",
    operatorId: environment.CLIENTS.ORALE_CASINO_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.ORALE_CASINO_COM.SITE_ID,
    name: casinoClient.ORALE_CASINO_LIVE,
    menu: navSimpleData,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.GOLDEN_PLAY_LIVE]: {
    class: "client-golden-play-com",
    operatorId: environment.CLIENTS.GOLDEN_PLAY_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.GOLDEN_PLAY_COM.SITE_ID,
    name: casinoClient.GOLDEN_PLAY_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.APUESTA_MEX_LIVE]: {
    class: "client-apuesta-mex",
    operatorId: environment.CLIENTS.APUESTA_MEX_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.APUESTA_MEX_COM.SITE_ID,
    name: casinoClient.APUESTA_MEX_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.JAZZ_247_AG_LIVE]: {
    class: "client-jazz-247-ag",
    operatorId: environment.CLIENTS.JAZZ_247_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.JAZZ_247_AG.SITE_ID,
    name: casinoClient.JAZZ_247_AG_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.JAZZ_888_LIVE]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.JAZZ_888_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.JAZZ_888_AG.SITE_ID,
    name: casinoClient.JAZZ_888_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.JAZZ_SPORTS_LIVE]: {
    class: "client-jazz",
    operatorId: environment.CLIENTS.JAZZ_SPORTS.OPERATOR_ID,
    siteID: environment.CLIENTS.JAZZ_SPORTS.SITE_ID,
    name: casinoClient.JAZZ_SPORTS_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: false,
      hideSearch: true,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BLACK_22_AG_LIVE]: {
    class: "client-bigbet-pa",
    operatorId: environment.CLIENTS.BLACK_22_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.BLACK_22_AG.SITE_ID,
    name: casinoClient.BLACK_22_AG_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.REAL_SPORTS_ODDS_COM_LIVE]: {
    class: "client-real-sports-odds-com",
    operatorId: environment.CLIENTS.REAL_SPORTS_ODDS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.REAL_SPORTS_ODDS_COM.SITE_ID,
    name: casinoClient.REAL_SPORTS_ODDS_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.FIVE_STAR_ACTION_LIVE]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.FIVE_STAR_ACTION_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.FIVE_STAR_ACTION_COM.SITE_ID,
    name: casinoClient.FIVE_STAR_ACTION_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.ALL_ACTION_365_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.ALL_ACTION_365_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.ALL_ACTION_365_AG.SITE_ID,
    name: casinoClient.ALL_ACTION_365_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PLAY_JW_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PLAY_JW_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_JW_AG.SITE_ID,
    name: casinoClient.PLAY_JW_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BETS_SWING_COM_LIVE]: {
    class: "client-bet-swing-com",
    operatorId: environment.CLIENTS.BETS_SWING_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.BETS_SWING_COM.SITE_ID,
    name: casinoClient.BETS_SWING_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EL_INMEJORABLE_LIVE]: {
    class: "client-el-inmejorable-com",
    operatorId: environment.CLIENTS.EL_INMEJORABLE_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.EL_INMEJORABLE_COM.SITE_ID,
    name: casinoClient.EL_INMEJORABLE_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EMPIRE_BETS_COM_LIVE]: {
    class: "client-empire-bets-com",
    operatorId: environment.CLIENTS.EMPIRE_BETS_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.EMPIRE_BETS_COM.SITE_ID,
    name: casinoClient.EMPIRE_BETS_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WAGER_WIRE_NET_LIVE]: {
    class: "client-wager-wire-net",
    operatorId: environment.CLIENTS.WAGER_WIRE_NET.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_WIRE_NET.SITE_ID,
    name: casinoClient.WAGER_WIRE_NET_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.DFW_COLLEGE_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.DFW_COLLEGE_ONLINE.OPERATOR_ID,
    siteID: environment.CLIENTS.DFW_COLLEGE_ONLINE.SITE_ID,
    name: casinoClient.DFW_COLLEGE_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PLAY_SPORTS_527_COM_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PLAY_SPORTS_527_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_SPORTS_527_COM.SITE_ID,
    name: casinoClient.PLAY_SPORTS_527_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BEAT_THE_ODDS_AG_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BEAT_THE_ODDS_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.BEAT_THE_ODDS_AG.SITE_ID,
    name: casinoClient.BEAT_THE_ODDS_AG_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PLAY_DAREDEVIL_COM_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PLAY_DAREDEVIL_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_DAREDEVIL_COM.SITE_ID,
    name: casinoClient.PLAY_DAREDEVIL_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WAGER_GIANT_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.WAGER_GIANT_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_GIANT_COM.SITE_ID,
    name: casinoClient.WAGER_GIANT_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.ODDS_2_WAGER_LIVE]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.ODDS_2_WAGER_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.ODDS_2_WAGER_COM.SITE_ID,
    name: casinoClient.ODDS_2_WAGER_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.SPORTS_FAN_MX_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.SPORTS_FAN_MX_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.SPORTS_FAN_MX_COM.SITE_ID,
    name: casinoClient.SPORTS_FAN_MX_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PICK2_SCORE_COM_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PICK2_SCORE_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.PICK2_SCORE_COM.SITE_ID,
    name: casinoClient.PICK2_SCORE_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.WAGERS_2x2_COM_LIVE]: {
    class: "client-wagers-2x2",
    operatorId: environment.CLIENTS.WAGERS_2x2_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGERS_2x2_COM.SITE_ID,
    name: casinoClient.WAGERS_2x2_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BET_VEGAS_527_LIVE]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.BET_VEGAS_527_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_VEGAS_527_COM.SITE_ID,
    name: casinoClient.BET_VEGAS_527_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BET_MY_LINES_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BET_MY_LINES_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_MY_LINES_COM.SITE_ID,
    name: casinoClient.BET_MY_LINES_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PLAY_SPORTS_123_COM_LIVE]: {
    class: "client-play-sports-123-com",
    operatorId: environment.CLIENTS.PLAY_SPORTS_123_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_SPORTS_123_COM.SITE_ID,
    name: casinoClient.PLAY_SPORTS_123_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.NS_SPORTS_CLUB_COM_LIVE]: {
    class: "client-ns-sports-club-com",
    operatorId: environment.CLIENTS.NS_SPORTS_CLUB_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.NS_SPORTS_CLUB_COM.SITE_ID,
    name: casinoClient.NS_SPORTS_CLUB_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BETGAMEDAY_NET_LIVE]: {
    class: "client-betgameday-net",
    operatorId: environment.CLIENTS.BETGAMEDAY_NET.OPERATOR_ID,
    siteID: environment.CLIENTS.BETGAMEDAY_NET.SITE_ID,
    name: casinoClient.BETGAMEDAY_NET_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.SPORTS_GANA_LIVE]: {
    class: "client-sports-gana-com",
    operatorId: environment.CLIENTS.SPORTS_GANA_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.SPORTS_GANA_COM.SITE_ID,
    name: casinoClient.SPORTS_GANA_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BET_AMERICA_LIVE]: {
    class: "client-bigbet-pa",
    operatorId: environment.CLIENTS.BET_AMERICA_MX.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_AMERICA_MX.SITE_ID,
    name: casinoClient.BET_AMERICA_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.XXX_BET_COM_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.XXX_BET_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.XXX_BET_COM.SITE_ID,
    name: casinoClient.XXX_BET_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.MJ_395_COM_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.MJ_395_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.MJ_395_COM.SITE_ID,
    name: casinoClient.MJ_395_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.EBETS_247_COM_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.EBETS_247_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.EBETS_247_COM.SITE_ID,
    name: casinoClient.EBETS_247_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.FAT_MOE_2G_LIVE]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.FAT_MOE_2G_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.FAT_MOE_2G_COM.SITE_ID,
    name: casinoClient.FAT_MOE_2G_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.EL_OTRO_CANAL_COM_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.EL_OTRO_CANAL_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.EL_OTRO_CANAL_COM.SITE_ID,
    name: casinoClient.EL_OTRO_CANAL_COM,
    menu: navSimpleData,
    operatorType: "live",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BROADWAY_SPORTS_MX_LIVE]: {
    class: "client-broadway-sports-mx",
    operatorId: environment.CLIENTS.BROADWAY_SPORTS_MX.OPERATOR_ID,
    siteID: environment.CLIENTS.BROADWAY_SPORTS_MX.SITE_ID,
    name: casinoClient.BROADWAY_SPORTS_MX_LIVE,
    menu: navSimpleData,
    operatorType: "live",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BIG_BET_PA_LIVE]: {
    class: "client-bigbet-pa",
    operatorId: environment.CLIENTS.BIG_BET_PA.OPERATOR_ID,
    siteID: environment.CLIENTS.BIG_BET_PA.SITE_ID,
    name: casinoClient.BIG_BET_PA_LIVE,
    menu: navSimpleData,
    operatorType: "live",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.CASTRO_SPORT_LIVE]: {
    class: "client-castro-sport",
    operatorId: environment.CLIENTS.CASTRO_SPORT_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.CASTRO_SPORT_COM.SITE_ID,
    name: casinoClient.CASTRO_SPORT_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BOOKIE_PRIME_LIVE]: {
    class: "client-bookie-prime",
    operatorId: environment.CLIENTS.BOOKIE_PRIME.OPERATOR_ID,
    siteID: environment.CLIENTS.BOOKIE_PRIME.SITE_ID,
    name: casinoClient.BOOKIE_PRIME_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.COLOMBO_LIVE]: {
    class: "client-colombo-bet",
    operatorId: environment.CLIENTS.COLOMBO_BET.OPERATOR_ID,
    siteID: environment.CLIENTS.COLOMBO_BET.SITE_ID,
    name: casinoClient.COLOMBO_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.BUDA_BET_COM_LIVE]: {
    class: "client-buda-bet-com",
    operatorId: environment.CLIENTS.BUDA_BET_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.BUDA_BET_COM.SITE_ID,
    name: casinoClient.BUDA_BET_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.PAY_DAY_LIVE]: {
    class: "client-pay-day",
    operatorId: environment.CLIENTS.PAY_DAY_GAMES.OPERATOR_ID,
    siteID: environment.CLIENTS.PAY_DAY_GAMES.SITE_ID,
    name: casinoClient.PAY_DAY_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.JC_SPORTS_BET_COM_LIVE]: {
    class: "client-jc-sports-bet-com",
    operatorId: environment.CLIENTS.JC_SPORTS_BET_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.JC_SPORTS_BET_COM.SITE_ID,
    name: casinoClient.JC_SPORTS_BET_COM_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.GALLERY_V_88_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.GALLERY_V_88_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.GALLERY_V_88_COM.SITE_ID,
    name: casinoClient.GALLERY_V_88_LIVE,
    categoryRecommendation: "LIVEDEALERS",
    operatorType: "live",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },

  /////////////////////
  // CASUAL CLIENTS //
  ///////////////////
  [casinoClient.BETAMA_CASUAL]: {
    class: "client-betama",
    operatorId: environment.CLIENTS.BETAMA.OPERATOR_ID,
    siteID: environment.CLIENTS.BETAMA.SITE_ID,
    name: casinoClient.BETAMA_CASUAL,
    categoryRecommendation: "CASUAL",
    operatorType: "casual",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },
  [casinoClient.I_BET_PIX_CASUAL]: {
    class: "client-i-bet-pix",
    operatorId: environment.CLIENTS.I_BET_PIX.OPERATOR_ID,
    siteID: environment.CLIENTS.I_BET_PIX.SITE_ID,
    name: casinoClient.I_BET_PIX_CASUAL,
    categoryRecommendation: "CASUAL",
    operatorType: "casual",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },

  /////////////////////
  // BINGO CLIENTS //
  ///////////////////
  [casinoClient.MR_REELS_BINGO]: {
    class: "client-mr-reels",
    operatorId: environment.CLIENTS.MR_REELS.OPERATOR_ID,
    siteID: environment.CLIENTS.MR_REELS.SITE_ID,
    name: casinoClient.MR_REELS_BINGO,
    categoryRecommendation: "BINGO",
    operatorType: "bingo",
    menu: navSimpleData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
    },
  },
  [casinoClient.GALLO_PLAY_COM_BINGO]: {
    class: "client-bet-vip-mx",
    operatorId: environment.CLIENTS.GALLO_PLAY_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.GALLO_PLAY_COM.SITE_ID,
    name: casinoClient.GALLO_PLAY_COM,
    categoryRecommendation: "BINGO",
    operatorType: "bingo",
    menu: defaultNavData,
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },

  ////////////////////
  // SLOTS CLIENTS //
  //////////////////
  [casinoClient.I_BET_PIX_SLOT]: {
    class: "client-i-bet-pix",
    operatorId: environment.CLIENTS.I_BET_PIX.OPERATOR_ID,
    siteID: environment.CLIENTS.I_BET_PIX.SITE_ID,
    name: casinoClient.I_BET_PIX_SLOT,
    categoryRecommendation: "SLOTS",
    operatorType: "slot", // Slot & Simplified Client
    menu: [],
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
    },
  },

  /////////////////////////
  // SIMPLIFIED VERSION //
  ///////////////////////
  [casinoClient.ABC_WAGERING_AG]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.ABC_WAGERING_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.ABC_WAGERING_AG.SITE_ID,
    name: casinoClient.ABC_WAGERING_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      hideProvider: false,
      themes: [],
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BET_BIG_CITY]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BET_BIG_CITY.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_BIG_CITY.SITE_ID,
    name: casinoClient.BET_BIG_CITY,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BEACH_SB]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.BEACH_SB.OPERATOR_ID,
    siteID: environment.CLIENTS.BEACH_SB.SITE_ID,
    name: casinoClient.BEACH_SB,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.PLAY_CR]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PLAY_CR.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_CR.SITE_ID,
    name: casinoClient.PLAY_CR,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.PLAY_JOON_BUG]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.PLAY_JOON_BUG.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_JOON_BUG.SITE_ID,
    name: casinoClient.PLAY_JOON_BUG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.PLAY_PL]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PLAY_PL.OPERATOR_ID,
    siteID: environment.CLIENTS.PLAY_PL.SITE_ID,
    name: casinoClient.PLAY_PL,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.WAGER_911]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.WAGER_911.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_911.SITE_ID,
    name: casinoClient.WAGER_911,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.WAGER_CONNECTION]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.WAGER_CONNECTION.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_CONNECTION.SITE_ID,
    name: casinoClient.WAGER_CONNECTION,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.WAGER_INTERNATIONAL]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.WAGER_INTERNATIONAL.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_INTERNATIONAL.SITE_ID,
    name: casinoClient.WAGER_INTERNATIONAL,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.WAGER_STORE]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.WAGER_STORE.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_STORE.SITE_ID,
    name: casinoClient.WAGER_STORE,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.WAGER_YOUR_MOJO]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.WAGER_YOUR_MOJO.OPERATOR_ID,
    siteID: environment.CLIENTS.WAGER_YOUR_MOJO.SITE_ID,
    name: casinoClient.WAGER_YOUR_MOJO,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.SPORTSBOOK_CASINO_AND_RACEBOOK]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.SPORTSBOOK_CASINO_AND_RACEBOOK.OPERATOR_ID,
    siteID: environment.CLIENTS.SPORTSBOOK_CASINO_AND_RACEBOOK.SITE_ID,
    name: casinoClient.SPORTSBOOK_CASINO_AND_RACEBOOK,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.LUCKY_CRAW_FISH]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.LUCKY_CRAW_FISH.OPERATOR_ID,
    siteID: environment.CLIENTS.LUCKY_CRAW_FISH.SITE_ID,
    name: casinoClient.LUCKY_CRAW_FISH,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.VANGUARD_SPORTS]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.VANGUARD_SPORTS.OPERATOR_ID,
    siteID: environment.CLIENTS.VANGUARD_SPORTS.SITE_ID,
    name: casinoClient.VANGUARD_SPORTS,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.THIRD_DOWN]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.THIRD_DOWN.OPERATOR_ID,
    siteID: environment.CLIENTS.THIRD_DOWN.SITE_ID,
    name: casinoClient.THIRD_DOWN,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.TOSS_AND_GO]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.TOSS_AND_GO.OPERATOR_ID,
    siteID: environment.CLIENTS.TOSS_AND_GO.SITE_ID,
    name: casinoClient.TOSS_AND_GO,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.DUBLIN_DAZE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.DUBLIN_DAZE.OPERATOR_ID,
    siteID: environment.CLIENTS.DUBLIN_DAZE.SITE_ID,
    name: casinoClient.DUBLIN_DAZE,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BEAT_THE_SPREAD]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.BEAT_THE_SPREAD.OPERATOR_ID,
    siteID: environment.CLIENTS.BEAT_THE_SPREAD.SITE_ID,
    name: casinoClient.BEAT_THE_SPREAD,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.WIN_AND_GRIN]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.WIN_AND_GRIN.OPERATOR_ID,
    siteID: environment.CLIENTS.WIN_AND_GRIN.SITE_ID,
    name: casinoClient.WIN_AND_GRIN,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.CLUB_PLAYER_24_7]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.CLUB_PLAYER_24_7.OPERATOR_ID,
    siteID: environment.CLIENTS.CLUB_PLAYER_24_7.SITE_ID,
    name: casinoClient.CLUB_PLAYER_24_7,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.DUBLIN_DAYS]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.DUBLIN_DAYS.OPERATOR_ID,
    siteID: environment.CLIENTS.DUBLIN_DAYS.SITE_ID,
    name: casinoClient.DUBLIN_DAYS,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.DFW_COLLEGE]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.DFW_COLLEGE.OPERATOR_ID,
    siteID: environment.CLIENTS.DFW_COLLEGE.SITE_ID,
    name: casinoClient.DFW_COLLEGE,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BEAT_THE_TOTAL]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BEAT_THE_TOTAL.OPERATOR_ID,
    siteID: environment.CLIENTS.BEAT_THE_TOTAL.SITE_ID,
    name: casinoClient.BEAT_THE_TOTAL,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.PREMIER_WAGER]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.PREMIER_WAGER.OPERATOR_ID,
    siteID: environment.CLIENTS.PREMIER_WAGER.SITE_ID,
    name: casinoClient.PREMIER_WAGER,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BET_WCS]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BET_WCS.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_WCS.SITE_ID,
    name: casinoClient.BET_WCS,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.PPH_WAGERING]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.PPH_WAGERING.OPERATOR_ID,
    siteID: environment.CLIENTS.PPH_WAGERING.SITE_ID,
    name: casinoClient.PPH_WAGERING,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.RAZR_CASINO]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.RAZR_CASINO.OPERATOR_ID,
    siteID: environment.CLIENTS.RAZR_CASINO.SITE_ID,
    name: casinoClient.RAZR_CASINO,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.ACTION_365_AG]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.ACTION_365_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.ACTION_365_AG.SITE_ID,
    name: casinoClient.ACTION_365_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BETS_AMERICA_007_SIMPLIFIED]: {
    class: "client-bets-america-007",
    operatorId: environment.CLIENTS.BETS_AMERICA_007.OPERATOR_ID,
    siteID: environment.CLIENTS.BETS_AMERICA_007.SITE_ID,
    name: casinoClient.BETS_AMERICA_007,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.TU_APUESTA_365_SIMPLIFIED]: {
    class: "client-tu-apuesta-365",
    operatorId: environment.CLIENTS.TU_APUESTA_365.OPERATOR_ID,
    siteID: environment.CLIENTS.TU_APUESTA_365.SITE_ID,
    name: casinoClient.TU_APUESTA_365,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: true,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.GAME_DAY_BET_SIMPLIFIED]: {
    class: "client-theme-light",
    operatorId: environment.CLIENTS.GAME_DAY_BET.OPERATOR_ID,
    siteID: environment.CLIENTS.GAME_DAY_BET.SITE_ID,
    name: casinoClient.GAME_DAY_BET_SIMPLIFIED,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BET_KINGS_888]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BET_KINGS_888.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_KINGS_888.SITE_ID,
    name: casinoClient.BET_KINGS_888,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.CASINO_BET_AG]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.CASINO_BET_AG.OPERATOR_ID,
    siteID: environment.CLIENTS.CASINO_BET_AG.SITE_ID,
    name: casinoClient.CASINO_BET_AG,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BET_ALL_LIVE]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BET_ALL_LIVE.OPERATOR_ID,
    siteID: environment.CLIENTS.BET_ALL_LIVE.SITE_ID,
    name: casinoClient.BET_ALL_LIVE,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.BOOKIE_PRIME_FUN]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.BOOKIE_PRIME_FUN.OPERATOR_ID,
    siteID: environment.CLIENTS.BOOKIE_PRIME_FUN.SITE_ID,
    name: casinoClient.BOOKIE_PRIME_FUN,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.GYM_AVENIDA_COM_SIMPLIFIED]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.GYM_AVENIDA_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.GYM_AVENIDA_COM.SITE_ID,
    name: casinoClient.GYM_AVENIDA_COM,
    menu: [],
    operatorType: "simplified",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: true,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.RED_107_NET]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.RED_107_NET.OPERATOR_ID,
    siteID: environment.CLIENTS.RED_107_NET.SITE_ID,
    name: casinoClient.RED_107_NET,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
  [casinoClient.LUCKY_BETS_21_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.LUCKY_BETS_21_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.LUCKY_BETS_21_COM.SITE_ID,
    name: casinoClient.LUCKY_BETS_21_COM,
    menu: defaultNavData,
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
      },
    },
  },
  [casinoClient.GALLERY_V_88_COM]: {
    class: "client-tico-bets",
    operatorId: environment.CLIENTS.GALLERY_V_88_COM.OPERATOR_ID,
    siteID: environment.CLIENTS.GALLERY_V_88_COM.SITE_ID,
    name: casinoClient.GALLERY_V_88_COM,
    menu: [],
    operatorType: "default",
    layout: {
      hideBanner: true,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: true,
      },
    },
  },
};

export const casinoDefaultAndSimplified = [
  casinoClient.BETS_AMERICA_007_SIMPLIFIED,
  casinoClient.TU_APUESTA_365_SIMPLIFIED,
  casinoClient.GYM_AVENIDA_COM_SIMPLIFIED,
] as string[];
