import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Breadcrumb } from "src/app/models/breadcrumb.model";

import { Location } from "@angular/common";
import { FormGroup } from "@angular/forms";
import { FilterGames, FilterOption } from "src/app/models/filters.model";

import { VendorsCount } from "src/app/models/vendors-count.model";
import { CategoriesCount } from "src/app/models/categories-count.model";
import { IDropdownSettings } from "ng-multiselect-dropdown";

import { TranslateService } from "@ngx-translate/core";
import { NavigationHistoryService } from "src/app/services/navigation-history.service";

@Component({
  selector: "app-header-block",
  templateUrl: "./header-block.component.html",
  styleUrls: ["./header-block.component.scss"],
})
export class HeaderBlockComponent implements OnInit {
  @Input() public title: string;
  @Input() public breadcrumb?: Breadcrumb[];
  @Input() public showFilter?: boolean;

  @Input() public showBack?: boolean = true;
  @Input() public hideSeeAll?: boolean;
  @Input() public filters?: FilterGames;

  @Input() public changeFilter: (data: FilterGames) => void;
  @Input() public listVendorCount: VendorsCount[] = [];
  @Input() public listCategoryCount: CategoriesCount[] = [];

  @Input() public disableCamelCase?: boolean;

  public vendorSelectedItems = [];
  public categorySelectedItems = [];
  public sortSelectedItems = [];

  public categorySettings: IDropdownSettings = {};
  public vendorSettings: IDropdownSettings = {};
  public sortSettings: IDropdownSettings = {};
  public formFilter: FormGroup;

  public listCategories: FilterOption[] = [];
  private canGoBack: boolean = false;
  public listSort: FilterOption[] = [
    {
      label: "A-Z",
      id: "A-Z",
    },
    {
      label: "Z-A",
      id: "Z-A",
    },
    // {
    //   label: this.translate.instant("page.games.filters.popular"),
    //   id: "Popular",
    // },
    {
      label: this.translate.instant("page.games.filters.featured"),
      id: "Featured",
    },
    {
      label: this.translate.instant("page.games.filters.newestOldest"),
      id: "Newest-Oldest",
    },
    {
      label: this.translate.instant("page.games.filters.oldestNewest"),
      id: "Oldest-Newest",
    },
  ];

  constructor(
    private _router: Router,
    private readonly _location: Location,
    public translate: TranslateService,
    private _navigation: NavigationHistoryService
  ) {}

  ngOnInit() {
    this.categorySettings = {
      singleSelection: false,
      idField: "code",
      textField: "name",
      allowSearchFilter: false,
      itemsShowLimit: 1,
      enableCheckAll: false,
    };

    this.vendorSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      allowSearchFilter: false,
      itemsShowLimit: 1,
      enableCheckAll: false,
    };

    this.sortSettings = {
      singleSelection: true,
      idField: "id",
      textField: "label",
      allowSearchFilter: false,
      enableCheckAll: false,
    };
  }

  goBack() {
    this._navigation.back();
  }

  goToAll() {
    this._router.navigate(["/games"], {
      queryParams: {
        title: this.title,
        category: this.filters.category || undefined,
        tags: this.filters.tags || undefined,
        vendor: this.filters.vendor || undefined,
        sort: this.filters.sort || undefined,
      },
    });
  }

  updateFilter() {
    this.filters = {
      ...this.filters,
      vendor:
        this.vendorSelectedItems
          .map(
            (item) =>
              this.listVendorCount.find((vendor) => vendor.id === item.id)
                .externalID
          )
          .join() || "",
      category:
        this.categorySelectedItems.map((item) => item.code).join() || "",
      sort: this.sortSelectedItems.map((item) => item.id).join() || "",
    };

    this.changeFilter(this.filters);
  }

  get getCategoryItems() {
    return this.listCategoryCount.reduce((category, curr) => {
      category[curr.code] = curr;
      return category;
    }, {});
  }

  get getVendorItems() {
    return this.listVendorCount.reduce((vendor, curr) => {
      vendor[curr.id] = curr;
      return vendor;
    }, {});
  }
}
