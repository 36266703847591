import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/lobby/lobby.module").then((module) => module.LobbyModule),
  },
  {
    path: "bonuses",
    loadChildren: () =>
      import("./pages/bonuses/bonuses.module").then(
        (module) => module.BonusesModule
      ),
  },
  {
    path: "featured-games",
    loadChildren: () =>
      import("./pages/featured-games/featured-games.module").then(
        (module) => module.FeaturedGamesModule
      ),
  },
  {
    path: "slots",
    loadChildren: () =>
      import("./pages/slots/slots.module").then((module) => module.SlotsModule),
  },
  {
    path: "jackpots",
    loadChildren: () =>
      import("./pages/jackpots/jackpots.module").then(
        (module) => module.JackpotsModule
      ),
  },
  {
    path: "live-dealers",
    loadChildren: () =>
      import("./pages/live-dealers/live-dealers.module").then(
        (module) => module.LiveDealersModule
      ),
  },
  {
    path: "table-games",
    loadChildren: () =>
      import("./pages/table-games/table-games.module").then(
        (module) => module.TableGamesModule
      ),
  },
  {
    path: "video-poker",
    loadChildren: () =>
      import("./pages/video-poker/video-poker.module").then(
        (module) => module.VideoPokerModule
      ),
  },
  {
    path: "casual",
    loadChildren: () =>
      import("./pages/other/other.module").then((module) => module.OtherModule),
  },
  {
    path: "bingo",
    loadChildren: () =>
      import("./pages/bingo/bingo.module").then((module) => module.BingoModule),
  },
  {
    path: "crash-games",
    loadChildren: () =>
      import("./pages/crash/crash.module").then((module) => module.CrashModule),
  },
  {
    path: "shows",
    loadChildren: () =>
      import("./pages/shows/shows.module").then((module) => module.ShowsModule),
  },
  {
    path: "roulette",
    loadChildren: () =>
      import("./pages/roulette/roulette.module").then(
        (module) => module.RouletteModule
      ),
  },
  {
    path: "new",
    loadChildren: () =>
      import("./pages/new/new.module").then((module) => module.NewModule),
  },
  {
    path: "bonus-buy",
    loadChildren: () =>
      import("./pages/bonus-buy/bonus-buy.module").then(
        (module) => module.BonusBuyModule
      ),
  },
  {
    path: "blackjack",
    loadChildren: () =>
      import("./pages/blackjack/blackjack.module").then(
        (module) => module.BlackjackModule
      ),
  },
  {
    path: "baccarat",
    loadChildren: () =>
      import("./pages/baccarat/baccarat.module").then(
        (module) => module.BaccaratModule
      ),
  },
  {
    path: "providers",
    loadChildren: () =>
      import("./pages/providers/providers.module").then(
        (module) => module.ProviderModule
      ),
  },
  {
    path: "themes",
    loadChildren: () =>
      import("./pages/themes/themes.module").then(
        (module) => module.ThemeModule
      ),
  },
  {
    path: "games",
    loadChildren: () =>
      import("./pages/games/games.module").then((module) => module.GamesModule),
  },
  {
    path: "game/:id",
    loadChildren: () =>
      import("./pages/game/game.module").then((module) => module.GameModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "disabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
