import { createAction, props } from "@ngrx/store";
import { Banner } from "src/app/models/banner.model";
import { BrandConfiguration, Modal, UiLayoutState } from "../model";

import { NavModel } from "@shared/layout/nav/data/nav.data";

export const updateUILayoutAction = createAction(
  "[UI Layout] Update UI Layout",
  props<UiLayoutState>()
);

export const updateIsLoadingAction = createAction(
  "[UI Layout] Update Loading",
  props<{ isLoading: boolean }>()
);

export const updateIsWebsiteLoadingAction = createAction(
  "[UI Layout] Update Is Website Loading",
  props<{ isWebsiteLoading: boolean }>()
);

export const setBannersAction = createAction(
  "[UI Layout] Update Banners",
  props<{
    bannerLayout: { banners: Banner[]; isLoading: boolean; load: boolean };
  }>()
);

export const setModalAction = createAction(
  "[UI Layout] Update Modal",
  props<{
    modal: Modal;
  }>()
);

export const updateBrandConfigurationAction = createAction(
  "[Customer] Update Brand Configuration",
  props<{
    brandConfiguration: BrandConfiguration;
  }>()
);

export const updateMenuAction = createAction(
  "[Customer] Update Menu",
  props<{
    menu: NavModel[];
  }>()
);
