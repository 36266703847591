/**
 * ==========================
 * S T A T E  T Y P E S
 * ==========================
 */
export class responsive {
  public breakpoint: number;
  public settings: SlideSettings;
}

export class SlideSettings {
  public slidesToShow: number;
  public slidesToScroll: number;
  public infinite?: boolean;
  public dots?: boolean;
  public rows: number;
  public slidesPerRow: number;
  public centerPadding: string;
  public centerMode: boolean;
  public nextArrow: string;
  public prevArrow: string;
  public variableWidth: boolean;
  public variableHeight: boolean;

  constructor() {
    this.slidesToShow = 3;
    this.slidesToScroll = 3;
    this.rows = 1;
    this.slidesPerRow = 1;
  }
}

export class SlideConfig {
  public dots: boolean;
  public rows: number;
  public infinite: boolean;
  public autoRotate: boolean;
  public autoRotateAfter: number;
  public autoplay: boolean;
  public autoplaySpeed: number;
  public speed: number;
  public slidesToShow: number;
  public slidesToScroll: number;
  public nextArrow: string;
  public prevArrow: string;
  public centerMode: boolean;
  public focusOnSelect: boolean;
  public asNavFor: string;
  public centerPadding: string;
  public responsive?: responsive[];
  public variableWidth: boolean;
  public variableHeight: boolean;
  public cssEase: string;

  constructor(init?: SlideConfig) {
    if (init) {
      Object.assign(this, init);
    } else {
      this.dots = true;
      this.rows = 1;
      this.infinite = true;
      this.autoRotate = false;
      this.autoRotateAfter = 1000;
      this.autoplay = false;
      this.autoplaySpeed = 3000;
      this.speed = 300;
      this.slidesToShow = 1;
      this.slidesToScroll = 1;
      this.nextArrow = '<div class="nav-btn next-slide"></div>';
      this.prevArrow = '<div class="nav-btn prev-slide"></div>';
      this.responsive = [];
      this.variableWidth = false;
      this.variableHeight = false;
    }
  }
}
