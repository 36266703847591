import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BannerComponent } from "./components/banner/banner.component";

import { NavComponent } from "./layout/nav/nav.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { HeaderComponent } from "./layout/header/header.component";

import { GameCategoryBlockComponent } from "./components/block-game-category/game-category-block.component";
import { BackgroundUrlPipe } from "./pipes/background-url.pipe";
import { GameProvidersBlockComponent } from "./components/block-game-providers/game-providers-block.component";

import { GameWinnersBlockComponent } from "./components/block-game-winners/game-winners-block.component";
import { VerticalGameCardComponent } from "./components/card-vertical-game/vertical-game-card.component";
import { HorizontalGameCardComponent } from "./components/card-horizontal-game/horizontal-game-card.component";

import { IconsModule } from "./components/icons/icons.module";
import { RouterModule } from "@angular/router";
import { HeaderBlockComponent } from "./components/header-block/header-block.component";

import { SearchComponent } from "./components/search/search.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService } from "../services/interceptor/interceptor.service";

import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { CardImageComponent } from "./components/card-image/card-image.component";
import { PaginationComponent } from "./components/pagination/pagination.component";

import { HorizontalResultCardComponent } from "./components/card-horizontal-result/horizontal-result-card.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchPipe } from "./pipes/search.pipe";

import { SkeletonLoaderComponent } from "./components/skeleton-loader/skeleton-loader.component";
import { BlockGamesComponent } from "./components/block-games/block-games.component";
import { DeviceDetectorService } from "ngx-device-detector";

import { EmptyStateComponent } from "./components/empty-state/empty-state.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { GameThemesBlockComponent } from "./components/block-game-themes/game-themes-block.component";
import { CardImageCoverComponent } from "./components/card-image-cover/card-image-cover.component";
import { BackdropComponent } from "./layout/backdrop/backdrop.component";

import { SnackbarComponent } from "./components/snackbar/snackbar.component";
import { ModalComponent } from "./layout/modal/modal.component";
import { LoadingComponent } from "./components/loading/loading.component";

@NgModule({
  declarations: [
    BackgroundUrlPipe,
    BannerComponent,
    NavComponent,
    BackdropComponent,
    HeaderComponent,
    GameCategoryBlockComponent,
    GameProvidersBlockComponent,
    GameThemesBlockComponent,
    GameWinnersBlockComponent,
    VerticalGameCardComponent,
    HorizontalGameCardComponent,
    HorizontalResultCardComponent,
    HeaderBlockComponent,
    SearchComponent,
    SafeUrlPipe,
    CardImageComponent,
    CardImageCoverComponent,
    PaginationComponent,
    SearchPipe,
    SkeletonLoaderComponent,
    BlockGamesComponent,
    EmptyStateComponent,
    SnackbarComponent,
    ModalComponent,
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    SlickCarouselModule,
    IconsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    BackgroundUrlPipe,
    SafeUrlPipe,
    SearchPipe,
    BannerComponent,
    GameCategoryBlockComponent,
    GameProvidersBlockComponent,
    GameThemesBlockComponent,
    GameWinnersBlockComponent,
    VerticalGameCardComponent,
    HorizontalResultCardComponent,
    HeaderComponent,
    NavComponent,
    BackdropComponent,
    LoadingComponent,
    SlickCarouselModule,
    IconsModule,
    HeaderBlockComponent,
    SearchComponent,
    PaginationComponent,
    SkeletonLoaderComponent,
    BlockGamesComponent,
    EmptyStateComponent,
    TranslateModule,
    SnackbarComponent,
    CardImageCoverComponent,
    ModalComponent,
  ],
  providers: [
    DeviceDetectorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class SharedModule {}
