import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/models/state.model";

import { selectIsLoadingUiLayout } from "src/app/state/ui-layout/selector/ui-layout.selector";

@Component({
  selector: "app-backdrop",
  templateUrl: "./backdrop.component.html",
  styleUrls: ["./backdrop.component.scss"],
})
export class BackdropComponent implements OnInit {
  public isLoading: boolean = false;

  constructor(private store: Store<AppState>) {
    this.store.select(selectIsLoadingUiLayout).subscribe((state) => {
      this.isLoading = state;
    });
  }

  ngOnInit() {}
}
