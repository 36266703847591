import { WinnerCategoryModel, Winner } from "src/app/models/winners.model";

const winners: Winner[] = [
  {
    player: "Annete_G",
    game: "In Caribean Poker",
    thumbnail: "assets/images/games/5Lions.png",
    provider: "assets/images/provider-betsoft.png",
    reward: "$510",
    bet: "300",
    payouts: "510",
  },
  {
    player: "Annete_G",
    game: "In Caribean Poker",
    thumbnail: "assets/images/games/HandOfMidas.png",
    provider: "assets/images/provider-betsoft.png",
    reward: "$510",
    bet: "300",
    payouts: "510",
  },
  {
    player: "Annete_G",
    game: "In Caribean Poker",
    thumbnail: "assets/images/games/GatesOfOlympus.png",
    provider: "assets/images/provider-betsoft.png",
    reward: "$510",
    bet: "300",
    payouts: "510",
  },
  {
    player: "Annete_G ",
    game: "In Caribean Poker",
    thumbnail: "assets/images/games/WildWildRiches.png",
    provider: "assets/images/provider-betsoft.png",
    reward: "$510",
    bet: "300",
    payouts: "510",
  },
  {
    player: "Annete_G ",
    game: "In Caribean Poker",
    thumbnail: "assets/images/games/FloatingDragon.png",
    provider: "assets/images/provider-betsoft.png",
    reward: "$510",
    bet: "300",
    payouts: "510",
  },
  {
    player: "Annete_G",
    game: "In Caribean Poker",
    thumbnail: "assets/images/games/SpartanKing.png",
    provider: "assets/images/provider-betsoft.png",
    reward: "$510",
    bet: "300",
    payouts: "510",
  },
  {
    player: "Annete_G",
    game: "In Caribean Poker",
    thumbnail: "assets/images/games/WolfGold.png",
    provider: "assets/images/provider-betsoft.png",
    reward: "$510",
    bet: "300",
    payouts: "510",
  },
];

export const winnersCategoryData: WinnerCategoryModel[] = [
  {
    name: "Latest Winners",
    winners: [...winners],
  },
  {
    name: "Top Winners",
    winners: [...winners],
  },
  {
    name: "Jackpots",
    winners: [...winners],
  },
];
