import { createReducer, on } from "@ngrx/store";
import { CustomerState } from "../model";
import {
  setFavoriteGameAction,
  removeFavoriteGameAction,
  setFavoriteGamesAction,
  updateUserInfoAction,
  setLastPlayedGamesAction,
  setGamificationTokenAction,
  updateCustomerProvidersAction,
  setIsDemoUserAction,
  setAllGamesUserAction,
  setDepositValidationAction,
} from "../actions/customer.action";

export const initialState: CustomerState = {
  loadFirstTime: false,
  userToken: "",
  playerId: "",
  agentId: "",
  language: "",
  country: "",
  isDemoUser: false,
  favoriteGames: {
    games: [],
    load: false,
    isLoading: false,
  },
  lastPlayedGames: {
    games: [],
    isLoading: false,
    load: false,
  },
  gamificationToken: "",
  customerProviders: [],
  allGames: [],
  depositValidation: false,
};

export const customerReducer = createReducer(
  initialState,
  on(updateUserInfoAction, (state, newState) => {
    return {
      ...state,
      ...newState,
    };
  }),
  on(setGamificationTokenAction, (state, newState) => {
    return {
      ...state,
      gamificationToken: newState.gamificationToken,
    };
  }),
  on(setFavoriteGamesAction, (state, newState) => {
    return {
      ...state,
      favoriteGames: newState.block,
    };
  }),
  on(setLastPlayedGamesAction, (state, newState) => {
    return {
      ...state,
      lastPlayedGames: newState.block,
    };
  }),
  on(setFavoriteGameAction, (state, newState) => {
    return {
      ...state,
      favoriteGames: {
        ...state.favoriteGames,
        games: [...state.favoriteGames.games, newState.game],
      },
    };
  }),
  on(removeFavoriteGameAction, (state, newState) => {
    return {
      ...state,
      favoriteGames: {
        ...state.favoriteGames,
        games: state.favoriteGames.games.filter(
          (favGame) => favGame.gameExternalID !== newState.gameExternalId
        ),
      },
    };
  }),
  on(updateCustomerProvidersAction, (state, newState) => {
    return {
      ...state,
      customerProviders: newState.customerProviders,
    };
  }),
  on(setIsDemoUserAction, (state, newState) => {
    return {
      ...state,
      isDemoUser: newState.isDemoUser,
    };
  }),
  on(setAllGamesUserAction, (state, newState) => {
    return {
      ...state,
      allGames: newState.allGames,
    };
  }),
  on(setDepositValidationAction, (state, newState) => {
    return {
      ...state,
      depositValidation: newState.depositValidation,
    };
  })
);
