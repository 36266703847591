import { menuType } from "src/app/enums/menu-types.enum";
import { BlockModel } from "src/app/models/games.model";
import { LiveDealersBlocks } from "src/app/pages/live-dealers/live-dealers.data";
import { SlotsBlocks } from "src/app/pages/slots/slots.data";
import { TableGamesBlocks } from "src/app/pages/table-games/table-games.data";

export type NavModel = {
  id: string;
  name: string;
  slug: string;
  isMatchExact?: boolean;
  type: menuType;
  blocks: BlockModel[];
};

export const defaultNavData: NavModel[] = [
  {
    name: "navigation.gameLobby",
    slug: "",
    id: "lobby",
    type: menuType.BLOCKS,
    blocks: [],
  },
  {
    name: "navigation.bonuses",
    slug: "/bonuses",
    id: "bonuses",
    type: menuType.BONUSES,
    blocks: [],
  },
  {
    name: "navigation.featuredGames",
    slug: "/featured-games",
    id: "featured-games",
    type: menuType.BLOCKS,
    blocks: [],
  },
  {
    name: "navigation.slots",
    slug: "/slots",
    id: "slots",
    type: menuType.BLOCKS,
    blocks: SlotsBlocks,
  },
  {
    name: "navigation.liveDealers",
    slug: "/live-dealers",
    id: "live-dealers",
    type: menuType.BLOCKS,
    blocks: LiveDealersBlocks,
  },
  {
    name: "navigation.tableGames",
    slug: "/table-games",
    id: "table-games",
    type: menuType.BLOCKS,
    blocks: TableGamesBlocks,
  },
  {
    name: "navigation.videoPoker",
    slug: "/video-poker",
    id: "video-poker",
    type: menuType.BLOCKS,
    blocks: [],
  },
  {
    name: "navigation.providers",
    slug: "/providers",
    id: "providers",
    type: menuType.PROVIDERS,
    blocks: [],
  },
];

export const customNavData: NavModel[] = [
  {
    name: "navigation.gameLobby",
    slug: "",
    id: "lobby",
    type: menuType.BLOCKS,
    blocks: [],
  },
  {
    name: "navigation.bonuses",
    slug: "/bonuses",
    id: "bonuses",
    type: menuType.BONUSES,
    blocks: [],
  },
  {
    name: "navigation.featuredGames",
    slug: "/featured-games",
    id: "featured-games",
    type: menuType.BLOCKS,
    blocks: [],
  },
  {
    name: "navigation.slots",
    slug: "/slots",
    id: "slots",
    type: menuType.BLOCKS,
    blocks: SlotsBlocks,
  },
  {
    name: "navigation.liveDealers",
    slug: "/live-dealers",
    id: "live-dealers",
    type: menuType.BLOCKS,
    blocks: LiveDealersBlocks,
  },
  {
    name: "navigation.videoBingo",
    slug: "/bingo",
    id: "bingo",
    type: menuType.BLOCKS,
    blocks: [],
  },
  {
    name: "navigation.tableGames",
    slug: "/table-games",
    id: "table-games",
    type: menuType.BLOCKS,
    blocks: TableGamesBlocks,
  },
  {
    name: "navigation.videoPoker",
    slug: "/video-poker",
    id: "video-poker",
    type: menuType.BLOCKS,
    blocks: [],
  },
  {
    name: "navigation.providers",
    slug: "/providers",
    id: "providers",
    type: menuType.PROVIDERS,
    blocks: [],
  },
];
// TODO: Remove when dynamic menu is developed
export const navSimpleData: NavModel[] = [
  {
    name: "navigation.gameLobby",
    slug: "",
    id: "lobby",
    type: menuType.BLOCKS,
    blocks: [],
  },
];
