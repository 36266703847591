import { Component, Input, OnInit } from "@angular/core";
import { SkeletonType } from "src/app/enums/skeleton-loader.enum";
import { FilterGames } from "src/app/models/filters.model";

import { BlockModel } from "src/app/models/games.model";
import { SlideConfig } from "src/app/models/slider.model";
import { DetectDeviceService } from "src/app/services/detect-device.service";

@Component({
  selector: "app-game-category-block",
  templateUrl: "./game-category-block.component.html",
  styleUrls: ["./game-category-block.component.scss"],
})
export class GameCategoryBlockComponent implements OnInit {
  @Input() public gamesCategory: BlockModel;
  @Input() public showFilter: boolean;

  @Input() public bonusId?: string;
  @Input() public disableCamelCase?: boolean = false;

  public filters: FilterGames;
  public slideConfig: SlideConfig;
  public isMobile: boolean;
  public skeletonBlock: SkeletonType = SkeletonType.BLOCK;

  constructor(private _detectDeviceService: DetectDeviceService) {}

  ngOnInit() {
    this.isMobile = this._detectDeviceService.getIsMobile();
    this.filters = {
      category: this.gamesCategory.filter
        ? this.gamesCategory.filter.category || ""
        : "",
      vendor: this.gamesCategory.filter
        ? this.gamesCategory.filter.vendor || ""
        : "",
      tags: this.gamesCategory.filter
        ? this.gamesCategory.filter.tags || ""
        : "",
      sort: this.gamesCategory.filter
        ? this.gamesCategory.filter.sort || ""
        : "",
      pageIndex: 1,
      pageSize: 24,
    };

    this.slideConfig = new SlideConfig(<SlideConfig>{
      slidesToScroll: 8,
      slidesToShow: 8,
      nextArrow: '<div class="nav-btn next-slide"></div>',
      prevArrow: '<div class="nav-btn prev-slide"></div>',
      dots: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 7,
          },
        },
        {
          breakpoint: 920,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            nextArrow: "",
            prevArrow: "",
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: "",
            prevArrow: "",
            dots: false,
          },
        },
        {
          breakpoint: 415,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: "",
            prevArrow: "",
            dots: false,
          },
        },
      ],
    });
  }
}
