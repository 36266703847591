import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { deviceType } from "src/app/constants/device.constant";

import { DeviceTypeInitProduct } from "src/app/enums/device-type.enum";
import { EmptyStateType } from "src/app/enums/empty-state.enum";
import { SkeletonType } from "src/app/enums/skeleton-loader.enum";
import { CategoriesCount } from "src/app/models/categories-count.model";

import { FilterGames } from "src/app/models/filters.model";
import { GameCategoryModel } from "src/app/models/games.model";
import { Pagination } from "src/app/models/pagination.model";

import { AppState } from "src/app/models/state.model";
import { VendorsCount } from "src/app/models/vendors-count.model";
import { DetectDeviceService } from "src/app/services/detect-device.service";

import { GamesService } from "src/app/services/games.service";
import { GamificationService } from "src/app/services/gamification.service";
import { BrandConfiguration } from "src/app/state/ui-layout/model";

import { selectUiLayoutState } from "src/app/state/ui-layout/selector/ui-layout.selector";

@Component({
  selector: "app-block-games",
  templateUrl: "./block-games.component.html",
  styleUrls: ["./block-games.component.scss"],
})
export class BlockGamesComponent implements OnInit {
  @Input() public gamesCategory: GameCategoryModel;
  @Input() public showBack?: boolean = true;

  public listVendorCount: VendorsCount[] = [];
  public listCategoryCount: CategoriesCount[] = [];

  public currentFilter: FilterGames;
  public pagination: Pagination = {
    totalPages: 0,
    totalRows: 0,
    pageIndex: 0,
  };
  public skeletonType: SkeletonType = SkeletonType.GAMES;
  public deviceType: DeviceTypeInitProduct;
  public brandConfiguration: BrandConfiguration;

  public EmptyStateType = EmptyStateType;

  constructor(
    public _gamesService: GamesService,
    public _gamificationService: GamificationService,
    private _detectDeviceService: DetectDeviceService,
    private store: Store<AppState>
  ) {
    this.store.select(selectUiLayoutState).subscribe((state) => {
      this.brandConfiguration = state.brandConfiguration;
    });
  }

  ngOnInit() {
    this.deviceType = this._detectDeviceService.getDeviceType();
    this.currentFilter = {
      ...this.gamesCategory.filter,
    };
    this.getGames(this.gamesCategory.filter);
    this.getFilterCount();
  }

  getGames(filters: FilterGames) {
    this.gamesCategory = {
      ...this.gamesCategory,
      isLoading: true,
    };
    this._gamesService
      .getGames({
        ...filters,
        platform: deviceType[this.deviceType],
        operatorID: this.brandConfiguration.operatorId,
      })
      .subscribe((data) => {
        if (data && data.games.length) {
          this.gamesCategory = {
            ...this.gamesCategory,
            games: data.games,
            isLoading: false,
          };
          this.pagination = {
            totalPages: data.totalPages,
            totalRows: data.totalRows,
            pageIndex: filters.pageIndex,
          };
        } else {
          this.gamesCategory = {
            ...this.gamesCategory,
            games: [],
            isLoading: false,
          };
          this.pagination = {
            totalPages: 0,
            totalRows: 0,
            pageIndex: 0,
          };
        }
      });
  }

  getFilterCount() {
    if (
      (this.gamesCategory.filter.category.length ||
        this.gamesCategory.filter.tags.length) &&
      this.gamesCategory.filter.vendor.length
    )
      return;

    if (this.gamesCategory.filter.category || this.gamesCategory.filter.tags) {
      this._gamificationService
        .getVendorsCount({
          operatorID: this.brandConfiguration.operatorId,
          category: this.gamesCategory.filter.category,
          tag: this.gamesCategory.filter.tags,
          platform: deviceType[this.deviceType],
          vendors: "",
        })
        .subscribe((data) => {
          if (data && data.vendors.length) {
            this.listVendorCount = data.vendors.filter(
              (category) => category.gamesCount
            );
          }
        });
    } else if (this.gamesCategory.filter.vendor) {
      this._gamificationService
        .getCategoriesCount({
          operatorID: this.brandConfiguration.operatorId,
          vendorExternalID: this.gamesCategory.filter.vendor || "",
          platform: deviceType[this.deviceType],
        })
        .subscribe((data) => {
          if (data.categories.length) {
            this.listCategoryCount = data.categories.filter(
              (category) => category.gamesCount
            );
          }
        });
    }
  }

  changeFilter = (filters: FilterGames): void => {
    this.currentFilter = {
      ...this.gamesCategory.filter,
      vendor: this.gamesCategory.filter.vendor
        ? this.gamesCategory.filter.vendor
        : filters.vendor,
      category: this.gamesCategory.filter.category
        ? this.gamesCategory.filter.category
        : filters.category,
      sort: this.gamesCategory.filter.sort
        ? this.gamesCategory.filter.sort
        : filters.sort,
      pageIndex: 1,
    };

    this.getGames(this.currentFilter);
  };

  changePage = (pageIndex: number) => {
    this.currentFilter = {
      ...this.gamesCategory.filter,
      vendor: this.gamesCategory.filter.vendor
        ? this.gamesCategory.filter.vendor
        : this.currentFilter.vendor || "",
      category: this.gamesCategory.filter.category
        ? this.gamesCategory.filter.category
        : this.currentFilter.category || "",
      sort: this.currentFilter.sort ? this.currentFilter.sort : null,
      pageIndex: pageIndex,
    };
    this.getGames(this.currentFilter);
  };
}
