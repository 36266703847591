import { Component, Input, OnInit } from "@angular/core";
import { SkeletonType } from "src/app/enums/skeleton-loader.enum";

@Component({
  selector: "app-skeleton-loader",
  templateUrl: "./skeleton-loader.component.html",
  styleUrls: ["./skeleton-loader.component.scss"],
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() public type: SkeletonType;

  public enumType = SkeletonType;

  constructor() {}

  ngOnInit() {}
}
