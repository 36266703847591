import { createReducer, on } from "@ngrx/store";
import { UiLayoutState } from "src/app/state/ui-layout/model";
import {
  setBannersAction,
  setModalAction,
  updateBrandConfigurationAction,
  updateIsLoadingAction,
  updateIsWebsiteLoadingAction,
  updateMenuAction,
  updateUILayoutAction,
} from "../actions/ui-layout.action";

export const initialState: UiLayoutState = {
  isLoading: false,
  isWebsiteLoading: true,
  bannerLayout: {
    banners: [],
    isLoading: true,
    load: false,
  },
  modal: {
    open: false,
    title: "",
    description: "",
  },
  brandConfiguration: {
    load: false,
    class: "",
    operatorId: -1,
    siteID: -1,
    name: "",
    blocks: [],
    isSimplifiedVersion: false,
    categoryRecommendation: "",
    menu: [],
    layout: {
      hideBanner: false,
      hideSearch: false,
      themes: [],
      hideProvider: false,
      bonus: {
        hidePage: false,
        hideCancelButton: false,
      },
    },
  },
};

export const uiLayoutReducer = createReducer(
  initialState,
  on(updateUILayoutAction, (state, newState) => {
    return {
      ...state,
      ...newState,
    };
  }),
  on(updateIsLoadingAction, (state, newState) => {
    return { ...state, isLoading: newState.isLoading };
  }),
  on(updateIsWebsiteLoadingAction, (state, newState) => {
    return { ...state, isWebsiteLoading: newState.isWebsiteLoading };
  }),
  on(setBannersAction, (state, newState) => {
    return { ...state, bannerLayout: newState.bannerLayout };
  }),
  on(setModalAction, (state, newState) => {
    return { ...state, modal: newState.modal };
  }),
  on(updateBrandConfigurationAction, (state, newState) => {
    return {
      ...state,
      brandConfiguration: newState.brandConfiguration,
    };
  }),
  on(updateMenuAction, (state, newState) => {
    return {
      ...state,
      brandConfiguration: {
        ...state.brandConfiguration,
        menu: newState.menu,
      },
    };
  })
);
