export enum casinoClient {
  // DEFAULT CLIENTS
  JAZZ = "JAZZ",
  JAZZ_SPORTS = "JAZZ_SPORTS",
  TICO_BETS = "TICO_BETS",
  ABC_ISLANDS = "ABC_ISLANDS",
  COCK_FIGHTS = "COCK_FIGHTS",
  LOOSE_LINES = "LOOSE_LINES",
  BET_CRYPTO = "BET_CRYPTO",
  GG_BETS = "GG_BETS",
  VIP_SPORTSBOOK = "VIP_SPORTSBOOK",
  BOSS_BET = "BOSS_BET",
  I_BET_SPORTS_COM = "I_BET_SPORTS_COM",
  YO_GANO = "YO_GANO",
  BET_VIP_MX = "BET_VIP_MX",
  MR_REELS = "MR_REELS",
  BE_BET_AG = "BE_BET_AG",
  JET_BET = "JET_BET",
  HIGH_ROLLERS_AG = "HIGH_ROLLERS_AG",
  BOOKIE_PRIME = "BOOKIE_PRIME",
  BETS_AMERICA_007 = "BETS_AMERICA_007",
  BETS_AMERICA_007_SIMPLIFIED = "BETS_AMERICA_007_SIMPLIFIED",
  JUGA_EN_CASA = "JUGA_EN_CASA",
  EMPELOTADOS_MX = "EMPELOTADOS_MX",
  EMPELOTADOS_MX_IQ = "EMPELOTADOS_MX_IQ",
  CARIBE_BETS = "CARIBE_BETS",
  HIPICA = "HIPICA",
  APUESTAS_BULLS = "APUESTAS_BULLS",
  TU_APUESTA_365 = "TU_APUESTA_365",
  TU_APUESTA_365_SIMPLIFIED = "TU_APUESTA_365_SIMPLIFIED",
  PAISANO_BET = "PAISANO_BET",
  EMPELOTADOS_COM = "EMPELOTADOS_COM",
  BHR_AG = "BHR_AG",
  BET_ODDS = "BET_ODDS",
  WIN_TECH_GS_COM = "WIN_TECH_GS_COM",
  WAGER_WIN_AG = "WAGER_WIN_AG",
  WINTORY_CASINO_COM = "WINTORY_CASINO_COM",
  SPORTS_KING_VIP = "SPORTS_KING_VIP",
  GALLO_PLAY_COM = "GALLO_PLAY_COM",
  AMEX_BET_MX = "AMEX_BET_MX",
  DRAGON_88_COM = "DRAGON_88_COM",
  DRAGON_88_VN_COM = "DRAGON_88_VN_COM",
  TIEMPOS_DIGITALES_COM = "TIEMPOS_DIGITALES_COM",
  HIGH_ROLLERS_IO = "HIGH_ROLLERS_IO",
  CRYPTO_BOOKIE_IO = "CRYPTO_BOOKIE_IO",
  VIP_CLUB_BET = "VIP_CLUB_BET",
  I_GAMBLER_IO = "I_GAMBLER_IO",
  APUESTA_PALACE_COM = "APUESTA_PALACE_COM",
  GYM_AVENIDA_COM = "GYM_AVENIDA_COM",
  GYM_365_COM = "GYM_365_COM",
  ANGUILLA_BET_AI = "ANGUILLA_BET_AI",
  APUESTAS_GOLDEN_COM = "APUESTAS_GOLDEN_COM",
  APUESTAS_LA_CIMA_COM = "APUESTAS_LA_CIMA_COM",
  APUESTAS_MARISEL_COM = "APUESTAS_MARISEL_COM",
  APUESTAS_FOLIATTI_COM = "APUESTAS_FOLIATTI_COM",
  BIG_BET_PA = "BIG_BET_PA",
  APUESTAS_VILLAS_PALACE_COM = "APUESTAS_VILLAS_PALACE_COM",
  APUESTAS_WINLAND_COM = "APUESTAS_WINLAND_COM",
  APUESTAS_WINPOT_COM = "APUESTAS_WINPOT_COM",
  TRIPLE_BET_AG = "TRIPLE_BET_AG",
  MAYBACH_CASINO_COM = "MAYBACH_CASINO_COM",
  KAPITAL_COM = "KAPITAL_COM",
  AC_NOSOTROS_COM = "AC_NOSOTROS_COM",
  STAR_SPORT_DO = "STAR_SPORT_DO",
  BETS_AMERICA_007_VIP = "BETS_AMERICA_007_VIP",
  CASINO_GCCCT_COM = "CASINO_GCCCT_COM",
  BETS_SWING_COM = "BETS_SWING_COM",
  EL_INMEJORABLE_COM = "EL_INMEJORABLE_COM",
  ALL_IN_SPORTBOOK = "ALL_IN_SPORTBOOK",
  WEST_COAST_SPORTBOOK = "WEST_COAST_SPORTBOOK",
  TRACK_AND_RACES_COM = "TRACK_AND_RACES_COM",
  TV_CLUB_SPORTS = "TV_CLUB_SPORTS",
  CLUB_CARIBBEAN_SPORTS = "CLUB_CARIBBEAN_SPORTS",
  SORTE_NET_BET = "SORTE_NET_BET",
  APUESTA_GOLD_COM = "APUESTA_GOLD_COM",
  ORALE_CASINO_COM = "ORALE_CASINO_COM",
  GOLDEN_PLAY_COM = "GOLDEN_PLAY_COM",
  APUESTA_MEX_COM = "APUESTA_MEX_COM",
  BET_MY_LINES_COM = "BET_MY_LINES_COM",
  SPORTS_GANA_COM = "SPORTS_GANA_COM",
  BET_AMERICA_MX = "BET_AMERICA_MX",
  EL_OTRO_CANAL_COM = "EL_OTRO_CANAL_COM",
  BROADWAY_SPORTS_MX = "BROADWAY_SPORTS_MX",
  LA_ROCA_007_COM = "LA_ROCA_007_COM",
  CASTRO_SPORT_COM = "CASTRO_SPORT_COM",
  VIPER_LINES = "VIPER_LINES",
  COLOMBO_BET = "COLOMBO_BET",
  PAY_DAY_GAMES = "PAY_DAY_GAMES",

  // SIMPLIFIED VERSION
  ABC_WAGERING_AG = "ABC_WAGERING_AG",
  BET_BIG_CITY = "BET_BIG_CITY",
  BEACH_SB = "BEACH_SB",
  PLAY_CR = "PLAY_CR",
  PLAY_JOON_BUG = "PLAY_JOON_BUG",
  PLAY_PL = "PLAY_PL",
  WAGER_911 = "WAGER_911",
  WAGER_CONNECTION = "WAGER_CONNECTION",
  WAGER_INTERNATIONAL = "WAGER_INTERNATIONAL",
  WAGER_STORE = "WAGER_STORE",
  WAGER_YOUR_MOJO = "WAGER_YOUR_MOJO",
  SPORTSBOOK_CASINO_AND_RACEBOOK = "SPORTSBOOK_CASINO_AND_RACEBOOK",
  LUCKY_CRAW_FISH = "LUCKY_CRAW_FISH",
  VANGUARD_SPORTS = "VANGUARD_SPORTS",
  THIRD_DOWN = "THIRD_DOWN",
  TOSS_AND_GO = "TOSS_AND_GO",
  DUBLIN_DAZE = "DUBLIN_DAZE",
  BEAT_THE_SPREAD = "BEAT_THE_SPREAD",
  WIN_AND_GRIN = "WIN_AND_GRIN",
  CLUB_PLAYER_24_7 = "CLUB_PLAYER_24_7",
  DUBLIN_DAYS = "DUBLIN_DAYS",
  DFW_COLLEGE = "DFW_COLLEGE",
  BEAT_THE_TOTAL = "BEAT_THE_TOTAL",
  RAZR_CASINO = "RAZR_CASINO",
  PREMIER_WAGER = "PREMIER_WAGER",
  BET_WCS = "BET_WCS",
  PPH_WAGERING = "PPH_WAGERING",
  ACTION_365_AG = "ACTION_365_AG",
  GAME_DAY_BET_SIMPLIFIED = "GAME_DAY_BET_SIMPLIFIED",
  ROOMS_365_VIP = "ROOMS_365_VIP",
  BET_KINGS_888 = "BET_KINGS_888",
  BET_FINE_24 = "BET_FINE_24",
  CASINO_BET_AG = "CASINO_BET_AG",
  BET_ALL_LIVE = "BET_ALL_LIVE",
  BOOKIE_PRIME_FUN = "BOOKIE_PRIME_FUN",
  GYM_AVENIDA_COM_SIMPLIFIED = "GYM_AVENIDA_COM_SIMPLIFIED",
  PRIME_TIME_SPORTSBOOK = "PRIME_TIME_SPORTSBOOK",
  BET_ALL_AG = "BET_ALL_AG",
  QUICK_CASH_AG = "QUICK_CASH_AG",
  JUEGA_YAA_COM = "JUEGA_YAA_COM",
  REGAL_CASINO = "REGAL_CASINO",
  RED_ZONE_247_CLUB = "RED_ZONE_247_CLUB",
  BLACK_22_AG = "BLACK_22_AG",
  JAZZ_247_AG = "JAZZ_247_AG",
  JAZZ_888_AG = "JAZZ_888_AG",
  REAL_SPORTS_ODDS_COM = "REAL_SPORTS_ODDS_COM",
  FIVE_STAR_ACTION_COM = "FIVE_STAR_ACTION_COM",
  ALL_ACTION_365_AG = "ALL_ACTION_365_AG",
  PLAY_JW_AG = "PLAY_JW_AG",
  EMPIRE_BETS_COM = "EMPIRE_BETS_COM",
  WAGER_WIRE_NET = "WAGER_WIRE_NET",
  DFW_COLLEGE_ONLINE = "DFW_COLLEGE_ONLINE",
  PLAY_SPORTS_527_COM = "PLAY_SPORTS_527_COM",
  BEAT_THE_ODDS_AG = "BEAT_THE_ODDS_AG",
  PLAY_DAREDEVIL_COM = "PLAY_DAREDEVIL_COM",
  WAGER_GIANT_COM = "WAGER_GIANT_COM",
  ODDS_2_WAGER_COM = "ODDS_2_WAGER_COM",
  SPORTS_FAN_MX_COM = "SPORTS_FAN_MX_COM",
  PICK2_SCORE_COM = "PICK2_SCORE_COM",
  WAGERS_2x2_COM = "WAGERS_2x2_COM",
  BET_VEGAS_527_COM = "BET_VEGAS_527_COM",
  PLAY_SPORTS_123_COM = "PLAY_SPORTS_123_COM",
  NS_SPORTS_CLUB_COM = "NS_SPORTS_CLUB_COM",
  BETGAMEDAY_NET = "BETGAMEDAY_NET",
  XXX_BET_COM = "XXX_BET_COM",
  MJ_395_COM = "MJ_395_COM",
  EBETS_247_COM = "EBETS_247_COM",
  FAT_MOE_2G_COM = "FAT_MOE_2G_COM",
  BUDA_BET_COM = "BUDA_BET_COM",
  JC_SPORTS_BET_COM = "JC_SPORTS_BET_COM",
  RED_107_NET = "RED_107_NET",
  LUCKY_BETS_21_COM = "LUCKY_BETS_21_COM",
  GALLERY_V_88_COM = "GALLERY_V_88_COM",

  // CUSTOMS CLIENTS
  BETAMA = "BETAMA",
  I_BET_SPORTS_AG = "I_BET_SPORTS_AG",
  I_BET_PIX = "I_BET_PIX",

  // LIVE CLIENTS
  BETAMA_LIVE = "BETAMA_LIVE",
  I_BET_PIX_LIVE = "I_BET_PIX_LIVE",
  MR_REELS_LIVE = "MR_REELS_LIVE",
  BET_VIP_MX_LIVE = "BET_VIP_MX_LIVE",
  EMPELOTADOS_LIVE_MX = "EMPELOTADOS_LIVE_MX",
  EMPELOTADOS_LIVE_MX_IQ = "EMPELOTADOS_LIVE_MX_IQ",
  JUGA_EN_CASA_LIVE = "JUGA_EN_CASA_LIVE",
  WIN_TECH_GS_COM_LIVE = "WIN_TECH_GS_COM_LIVE",
  GALLO_PLAY_COM_LIVE = "GALLO_PLAY_COM_LIVE",
  TRACK_AND_RACES_LIVE = "TRACK_AND_RACES_LIVE",
  SORTE_NET_BET_LIVE = "SORTE_NET_BET_LIVE",
  JUEGA_YAA_LIVE = "JUEGA_YAA_LIVE",
  APUESTA_GOLD_LIVE = "APUESTA_GOLD_LIVE",
  ORALE_CASINO_LIVE = "ORALE_CASINO_LIVE",
  GOLDEN_PLAY_LIVE = "GOLDEN_PLAY_LIVE",
  APUESTA_MEX_LIVE = "APUESTA_MEX_LIVE",
  BLACK_22_AG_LIVE = "BLACK_22_AG_LIVE",
  JAZZ_247_AG_LIVE = "JAZZ_247_AG_LIVE",
  JAZZ_888_LIVE = "JAZZ_888_LIVE",
  JAZZ_SPORTS_LIVE = "JAZZ_SPORTS_LIVE",
  REAL_SPORTS_ODDS_COM_LIVE = "REAL_SPORTS_ODDS_COM_LIVE",
  FIVE_STAR_ACTION_LIVE = "FIVE_STAR_ACTION_LIVE",
  ALL_ACTION_365_LIVE = "ALL_ACTION_365_LIVE",
  PLAY_JW_LIVE = "PLAY_JW_LIVE",
  BETS_SWING_COM_LIVE = "BETS_SWING_COM_LIVE",
  EL_INMEJORABLE_LIVE = "EL_INMEJORABLE_LIVE",
  EMPIRE_BETS_COM_LIVE = "EMPIRE_BETS_COM_LIVE",
  WAGER_WIRE_NET_LIVE = "WAGER_WIRE_NET_LIVE",
  DFW_COLLEGE_LIVE = "DFW_COLLEGE_LIVE",
  PLAY_SPORTS_527_COM_LIVE = "PLAY_SPORTS_527_COM_LIVE",
  BEAT_THE_ODDS_AG_LIVE = "BEAT_THE_ODDS_AG_LIVE",
  PLAY_DAREDEVIL_COM_LIVE = "PLAY_DAREDEVIL_COM_LIVE",
  WAGER_GIANT_LIVE = "WAGER_GIANT_LIVE",
  ODDS_2_WAGER_LIVE = "ODDS_2_WAGER_LIVE",
  SPORTS_FAN_MX_LIVE = "SPORTS_FAN_MX_LIVE",
  PICK2_SCORE_COM_LIVE = "PICK2_SCORE_COM_LIVE",
  WAGERS_2x2_COM_LIVE = "WAGERS_2x2_COM_LIVE",
  BET_VEGAS_527_LIVE = "BET_VEGAS_527_LIVE",
  BET_MY_LINES_LIVE = "BET_MY_LINES_LIVE",
  PLAY_SPORTS_123_COM_LIVE = "PLAY_SPORTS_123_COM_LIVE",
  NS_SPORTS_CLUB_COM_LIVE = "NS_SPORTS_CLUB_COM_LIVE",
  BETGAMEDAY_NET_LIVE = "BETGAMEDAY_NET_LIVE",
  SPORTS_GANA_LIVE = "SPORTS_GANA_LIVE",
  BET_AMERICA_LIVE = "BET_AMERICA_LIVE",
  XXX_BET_COM_LIVE = "XXX_BET_COM_LIVE",
  MJ_395_COM_LIVE = "MJ_395_COM_LIVE",
  EBETS_247_COM_LIVE = "EBETS_247_COM_LIVE",
  FAT_MOE_2G_LIVE = "FAT_MOE_2G_LIVE",
  EL_OTRO_CANAL_COM_LIVE = "EL_OTRO_CANAL_COM_LIVE",
  BROADWAY_SPORTS_MX_LIVE = "BROADWAY_SPORTS_MX_LIVE",
  BIG_BET_PA_LIVE = "BIG_BET_PA_LIVE",
  CASTRO_SPORT_LIVE = "CASTRO_SPORT_LIVE",
  BOOKIE_PRIME_LIVE = "BOOKIE_PRIME_LIVE",
  COLOMBO_LIVE = "COLOMBO_LIVE",
  BUDA_BET_COM_LIVE = "BUDA_BET_COM_LIVE",
  PAY_DAY_LIVE = "PAY_DAY_LIVE",
  JC_SPORTS_BET_COM_LIVE = "JC_SPORTS_BET_COM_LIVE",
  GALLERY_V_88_LIVE = "GALLERY_V_88_LIVE",

  // CASUAL CLIENTS
  BETAMA_CASUAL = "BETAMA_CASUAL",
  I_BET_PIX_CASUAL = "I_BET_PIX_CASUAL",

  // BINGO CLIENTS
  MR_REELS_BINGO = "MR_REELS_BINGO",
  GALLO_PLAY_COM_BINGO = "GALLO_PLAY_COM_BINGO",

  // SLOTS CLIENTS
  I_BET_PIX_SLOT = "I_BET_PIX_SLOT",
}
