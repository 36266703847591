export enum BONUS_TYPE_ENUM {
  casino = 1,
  sports = 2,
  freePlay = 3,
  freeSpin = 4,
  cashBonus = 5,
}

export enum BonusStatusType {
  BONUS_ACTIVE = 1,
  BONUS_RELEASE,
  BONUS_USED_UP,
  BONUS_CANCELED,
  BONUS_EXPIRED,
  BONUS_LIMITS = 406,
  BONUS_EXCLUDE_GAMES = 407,
}
