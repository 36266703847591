import { Component, Input, OnInit } from "@angular/core";
import { emptyStateConstant } from "src/app/constants/empty-state.constant";
import { EmptyStateType } from "src/app/enums/empty-state.enum";

@Component({
  selector: "app-empty-state",
  templateUrl: "./empty-state.component.html",
  styleUrls: ["./empty-state.component.scss"],
})
export class EmptyStateComponent implements OnInit {
  @Input() public icon: EmptyStateType;
  @Input() public title: string;
  @Input() public message?: string;

  public iconUrl = "";
  constructor() {}

  ngOnInit() {
    this.iconUrl =
      emptyStateConstant[this.icon] ||
      emptyStateConstant[EmptyStateType.NO_GAMES];
  }
}
