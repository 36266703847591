import { ViewportScroller } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router, Scroll } from "@angular/router";

import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { delay, filter } from "rxjs/operators";

import { environment } from "src/environments/environment";
import {
  CasinoClient,
  casinoDefaultAndSimplified,
} from "./constants/client-configuration.constant";
import { AppState } from "./models/state.model";

import { GamificationService } from "./services/gamification.service";
import { NavigationHistoryService } from "./services/navigation-history.service";
import { ProvidersService } from "./services/providers.service";

import {
  setDepositValidationAction,
  setGamificationTokenAction,
  setIsDemoUserAction,
  updateCustomerProvidersAction,
  updateUserInfoAction,
} from "./state/costumer/actions/customer.action";
import { selectCustomerState } from "./state/costumer/selector/customer.selector";
import {
  updateBrandConfigurationAction,
  updateIsLoadingAction,
  updateIsWebsiteLoadingAction,
  updateUILayoutAction,
} from "./state/ui-layout/actions/ui-layout.action";

import { selectUiLayoutState } from "./state/ui-layout/selector/ui-layout.selector";
import { BrandConfiguration, UiLayoutState } from "./state/ui-layout/model";
import { casinoClient } from "./enums/casinoClient.enum";

import { providerOrderForBetamaAndIBetPix } from "./pages/providers/providers.data";
import { DetectDeviceService } from "./services/detect-device.service";
import { DeviceTypeInitProduct } from "./enums/device-type.enum";

import { deviceType } from "./constants/device.constant";
import { ProvidersGamification } from "./models/providers.model";
import { UtilsService } from "./services/utils.service";
import { DepositService } from "./services/deposit.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  private userToken: string = "";
  private language: string;
  private playerId: string;

  private country: string;
  private loadReady: boolean = false;
  private loadFirstTime: boolean;

  public gamificationToken: string;
  public isWebsiteLoading: boolean;
  public brandConfiguration: BrandConfiguration;

  public deviceType: DeviceTypeInitProduct;
  public isMobile: boolean;
  public uiLayout: UiLayoutState;

  private agentId: string;

  constructor(
    private _translateService: TranslateService,
    private readonly _activatedRoute: ActivatedRoute,
    private _store: Store<AppState>,
    public _navigation: NavigationHistoryService,
    private _gamificationService: GamificationService,
    private _router: Router,
    private _viewportScroller: ViewportScroller,
    private _ProvidersService: ProvidersService,
    private _detectDeviceService: DetectDeviceService,
    private _utilsService: UtilsService,
    private _depositService: DepositService
  ) {
    this._translateService.setDefaultLang("en");
    this._store.select(selectCustomerState).subscribe((state) => {
      this.userToken = state.userToken;
      this.playerId = state.playerId;
      this.agentId = state.agentId;
      this.language = state.language;
      this.loadFirstTime = state.loadFirstTime;
      this.gamificationToken = state.gamificationToken;
    });
    this._store.select(selectUiLayoutState).subscribe((state) => {
      this.isWebsiteLoading = state.isWebsiteLoading;
      this.brandConfiguration = state.brandConfiguration;
      this.uiLayout = state;
    });

    this._router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .pipe(delay(1))
      .subscribe((e) => {
        if (e.position) {
          this._viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this._viewportScroller.scrollToAnchor(e.anchor);
        }
      });
  }

  ngOnInit() {
    this.isMobile = window.innerWidth > 580 ? false : true;
    this.deviceType = this._detectDeviceService.getDeviceType();
    this.getUrlParams();
    this._store.dispatch(updateIsLoadingAction({ isLoading: true }));
  }

  getUrlParams() {
    this._activatedRoute.queryParamMap.subscribe((params) => {
      if (params.get("language") && !this.language) {
        this._translateService.use(params.get("language"));
      }
      if (this.loadReady && !this.loadFirstTime) {
        this._store.dispatch(
          updateUserInfoAction({
            loadFirstTime: true,
            userToken: this.userToken || params.get("token") || "",
            playerId:
              this.playerId || params.get("playerId")
                ? atob(params.get("playerId"))
                : "",
            agentId:
              this.agentId || params.get("agentId")
                ? atob(params.get("agentId"))
                : "",
            language: this.language || params.get("language") || "en",
            country: this.country || params.get("country") || "",
          })
        );

        if (!this.brandConfiguration.load) {
          const currentClient = params.get("casinoClient");
          this._store.dispatch(
            updateUILayoutAction({
              ...this.uiLayout,
              brandConfiguration: {
                ...CasinoClient[currentClient],
                load: true,
                websiteIDVariant: casinoDefaultAndSimplified.includes(
                  currentClient
                )
                  ? currentClient
                  : undefined,
              },
              isMobile: this.isMobile,
              platform: deviceType[this.deviceType],
            })
          );
          if (this._utilsService.isDepositValidationRequired(currentClient)) {
            this.getDepositValidation();
          }
        }
        this.getGamificationToken();
        this.validateIsDemoUse();
      }

      this.loadReady = true;
    });
  }

  private getProvidersGamification(): void {
    this._ProvidersService
      .getProvidersGamification({})
      .subscribe((response) => {
        if (
          this.brandConfiguration.name == casinoClient.BETAMA ||
          this.brandConfiguration.name == casinoClient.I_BET_PIX
        ) {
          response.vendors.forEach((res, index) => {
            if (
              providerOrderForBetamaAndIBetPix.find(
                (provider) => provider == res.name
              )
            ) {
              response.vendors.splice(index, 1);
              response.vendors.unshift(res);
            }
          });
        }
        this._store.dispatch(
          updateCustomerProvidersAction({
            customerProviders: response.vendors.map(
              (vendor: ProvidersGamification) => {
                return { ...vendor, id: Number(vendor.externalID) };
              }
            ),
          })
        );
      });
  }

  private getDepositValidation(): void {
    this._depositService
      .getDepositValidation({
        playerId: Number(this.playerId),
        siteId: this.brandConfiguration.siteID,
      })
      .subscribe((response) => {
        this._store.dispatch(
          setDepositValidationAction({
            depositValidation: response.depositValidation,
          })
        );
      });
  }

  private getOperatorInfo(): void {
    this._gamificationService
      .getOperatorInfo({
        operatorID: this.brandConfiguration.operatorId,
        lang: this.language,
        operatorType: this.brandConfiguration.operatorType || "",
        ...(this.brandConfiguration.websiteIDVariant && {
          websiteID: this.brandConfiguration.websiteIDVariant,
        }),
      })
      .subscribe((response) => {
        this._store.dispatch(
          updateIsWebsiteLoadingAction({ isWebsiteLoading: false })
        );
        this._store.dispatch(updateIsLoadingAction({ isLoading: false }));
        this._store.dispatch(
          updateBrandConfigurationAction({
            brandConfiguration: {
              ...this.brandConfiguration,
              isSimplifiedVersion: response.isSimplifiedVersion,
              blocks: response.blocks.map((block) =>
                this._utilsService.getBlockFormat(block)
              ),
              layout: {
                ...this.brandConfiguration.layout,
                themes: response.layout.themes.map((theme) =>
                  this._utilsService.getProviderFormat(theme)
                ),
              },
              siteID: Number(response.siteID),
              operatorId: response.id,
            },
          })
        );
      });
  }

  private getGamificationToken(): void {
    this._gamificationService
      .getToken({
        username: environment.GAMIFICATION_API.USERNAME,
        password: environment.GAMIFICATION_API.PASSWORD,
        ipAddress: "",
        browser: "",
      })
      .subscribe((response) => {
        if (response.token) {
          this._store.dispatch(
            setGamificationTokenAction({
              gamificationToken: response.token,
            })
          );
          this.getOperatorInfo();
          this.getProvidersGamification();
        }
      });
  }

  private validateIsDemoUse(): void {
    if (atob(this.userToken).includes(environment.DEMO_USER + ":")) {
      this._store.dispatch(
        setIsDemoUserAction({
          isDemoUser: true,
        })
      );
    }
  }
}
