import { createReducer, on } from "@ngrx/store";
import { BonusState } from "../model";
import {
  addBonusesAction,
  addCurrentBonusAction,
  loadBonusesAction,
  openModalSuccessActivationAction,
  openModalCancelBonusAction,
  openModalCurrentBonusAction,
} from "../actions/bonus.action";

import { BONUS_TYPE_ENUM } from "src/app/enums/bonus.enum";

export const initialState: BonusState = {
  bonuses: [],
  openCancelModal: false,
  openCurrentBonusModal: false,
  openSuccessActivationModal: false,
  currentBonus: {
    bonus: {
      id: 0,
      bonusName: "",
      playerId: 0,
      bonusCode: "",
      bonusAmount: 0,
      depositAmount: 0,
      rollover: 0,
      rolloverCoefficcient: 0, //rolloverCoefficient
      status: 0,
      grantedAmount: 0,
      winningsAmount: 0,
      rolloverAmount: 0,
      rolloverGranted: 0,
      deposiId: 0, //depositId
      bonusCancelled: 0,
      grantedDeposit: 0,
      isWalletActive: true,
      walletType: BONUS_TYPE_ENUM.casino,
      createdAt: "",
      updatedAt: "",
      providers: [],
      Games: {},
    },
    games: [],
  },
  isLoadingBonuses: false,
  pagination: {
    totalPages: 0,
    totalRows: 0,
    pageIndex: 0,
  },
};

export const bonusesReducer = createReducer(
  initialState,
  on(loadBonusesAction, (state) => {
    return { ...state, isLoadingBonuses: true, bonuses: [] };
  }),

  on(addBonusesAction, (state, newState) => {
    return {
      ...state,
      isLoadingBonuses: false,
      bonuses: newState.bonuses,
      //TODO: Remove pagination
      // pagination: newState.pagination,
    };
  }),
  on(addCurrentBonusAction, (state, newState) => {
    return { ...state, currentBonus: newState };
  }),
  on(openModalCancelBonusAction, (state, newState) => {
    return { ...state, openCancelModal: newState.open };
  }),
  on(openModalSuccessActivationAction, (state, newState) => {
    return { ...state, openSuccessActivationModal: newState.open };
  }),
  on(openModalCurrentBonusAction, (state, newState) => {
    return { ...state, openCurrentBonusModal: newState.open };
  })
);
