import { Component, Input } from "@angular/core";
import { Game } from "src/app/models/games.model";
import { Router } from "@angular/router";

import { Store } from "@ngrx/store";
import { AppState } from "src/app/models/state.model";
import { selectCustomerState } from "src/app/state/costumer/selector/customer.selector";

import { setModalAction } from "src/app/state/ui-layout/actions/ui-layout.action";
import { BrandConfiguration } from "src/app/state/ui-layout/model";
import { selectUiLayoutState } from "src/app/state/ui-layout/selector/ui-layout.selector";

import { GameService } from "src/app/services/game.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { OPENED_PROVIDERS_NEW_TAB } from "src/app/enums/providers.enum";

@Component({
  selector: "app-vertical-game-card",
  templateUrl: "./vertical-game-card.component.html",
  styleUrls: ["./vertical-game-card.component.scss"],
})
export class VerticalGameCardComponent {
  @Input() public game: Game;
  @Input() public bonusId?: number;
  private isDemoUser: boolean = false;

  public isMobile: boolean;
  public brandConfiguration: BrandConfiguration;

  constructor(
    private _router: Router,
    private _store: Store<AppState>,
    private readonly _gameService: GameService,
    _deviceService: DeviceDetectorService
  ) {
    this._store.select(selectCustomerState).subscribe((state) => {
      this.isDemoUser = state.isDemoUser;
    });
    this._store.select(selectUiLayoutState).subscribe((state) => {
      this.brandConfiguration = state.brandConfiguration;
    });
    this.isMobile = _deviceService.isMobile();
  }

  goToGame(event: MouseEvent) {
    let secureArea = event.view.innerHeight - 300;
    if (this.isDemoUser) {
      this._store.dispatch(
        setModalAction({
          modal: {
            open: true,
            title: "common.loginRequiredTitle",
            description: "common.loginRequiredMessage",
            size: "xs",
            yPosition:
              event.clientY > secureArea ? event.clientY - 300 : event.clientY,
          },
        })
      );
    } else {
      if (
        this.isMobile &&
        Object.values(OPENED_PROVIDERS_NEW_TAB).includes(
          this.game.vendorExternalID as OPENED_PROVIDERS_NEW_TAB
        )
      ) {
        this._gameService.openNewTab(
          this.game.gameExternalID,
          this.bonusId ? Number(this.bonusId) : null
        );
      } else {
        this._router.navigate(["/game", this.game.gameExternalID], {
          queryParams: {
            category: undefined,
            tags: undefined,
            bonusId: this.bonusId,
          },
        });
      }
    }
  }
}
