export enum GAMIFICATION_PROVIDER_INTEGRATIONS {
  PRAGMATIC = "95",
  PRAGMATIC_LIVE = "103",
  BET_SOFT = "15",
  EVOLUTION_GAMING = "34",
  BIG_TIME_GAMING = "145",
  NETENT = "144",
  RED_TIGER = "93",
  EZUGI = "5",
  BGAMING = "97",
  NO_LIMIT_CITY = "99", // Only Dev
}

export enum PROVIDER_INTEGRATIONS {
  TOM_HORN = 126,
  PGS = 151,
}

export enum OPENED_PROVIDERS_NEW_TAB {
  MOBITAZ = "8",
}
