import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

import { Observable } from "rxjs";
import {
  GamesIDsPayload,
  GamesPayload,
  GamesResponse,
} from "../models/games.model";
import { Store } from "@ngrx/store";

import { AppState } from "../models/state.model";
import { selectCustomerState } from "../state/costumer/selector/customer.selector";

@Injectable({ providedIn: "root" })
export class GamesService {
  private GET_GAMES_API: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.LOBBY;
  private GET_GAMES_BY_IDS_API: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.GAMES;
  public language: string;

  public country: string;
  public UID: string;
  public agentId: string;

  constructor(public http: HttpClient, private _store: Store<AppState>) {
    this._store.select(selectCustomerState).subscribe((state) => {
      this.language = state.language;
      this.country = state.country;
      this.UID = state.playerId;
      this.agentId = state.agentId;
    });
  }

  public getGames(data: GamesPayload): Observable<GamesResponse> {
    return this.http.post<GamesResponse>(
      this.GET_GAMES_API,
      JSON.stringify({
        ...data,
        country: this.country || undefined,
        language: this.language || undefined,
        UID: this.UID || "",
        agentId: this.agentId || "",
      })
    );
  }

  public getGamesByIDs(data: GamesIDsPayload): Observable<GamesResponse> {
    return this.http.post<GamesResponse>(
      this.GET_GAMES_BY_IDS_API,
      JSON.stringify({
        ...data,
        language: this.language || undefined,
        UID: this.UID || "",
        agentId: this.agentId || "",
      })
    );
  }
}
