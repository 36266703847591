import { Component, OnInit } from "@angular/core";
import { SlideConfig } from "src/app/models/slider.model";
import { WinnerCategoryModel } from "src/app/models/winners.model";

import { winnersCategoryData } from "./game-winners-block.data";

@Component({
  selector: "app-game-winners-block",
  templateUrl: "./game-winners-block.component.html",
  styleUrls: ["./game-winners-block.component.scss"],
})
export class GameWinnersBlockComponent implements OnInit {
  public winnersCategory: WinnerCategoryModel[];
  public slideConfig: SlideConfig;

  constructor() {}

  ngOnInit() {
    this.winnersCategory = winnersCategoryData;
    this.slideConfig = new SlideConfig(<SlideConfig>{
      slidesToScroll: 3,
      slidesToShow: 3,
      nextArrow: '<div class="nav-btn next-slide"></div>',
      prevArrow: '<div class="nav-btn prev-slide"></div>',
      dots: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 5000,
      centerMode: true,
      centerPadding: "0",
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
}
