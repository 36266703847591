import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { AppState } from "src/app/models/state.model";
import {
  selectCustomerState,
  selectGamificationToken,
} from "src/app/state/costumer/selector/customer.selector";

import { BrandConfiguration } from "src/app/state/ui-layout/model";
import { selectUiLayoutState } from "src/app/state/ui-layout/selector/ui-layout.selector";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class InterceptorService implements HttpInterceptor {
  private userToken: string = "";
  private gamificationToken: string = "";
  public brandConfiguration: BrandConfiguration;

  constructor(private store: Store<AppState>) {
    this.store.select(selectCustomerState).subscribe((state) => {
      this.userToken = state.userToken;
    });

    this.store.select(selectGamificationToken).subscribe((state) => {
      this.gamificationToken = state;
    });

    this.store.select(selectUiLayoutState).subscribe((state) => {
      this.brandConfiguration = state.brandConfiguration;
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authorization = "";

    const excludeUrl = [environment.PROVIDER_INTEGRATION_API.SERVER_URL];

    if (excludeUrl.some((url) => req.url.includes(url))) {
      return next.handle(req);
    }

    if (req.url.includes(environment.GAMIFICATION_API.SERVER_URL)) {
      authorization = `Bearer ${this.gamificationToken}`;
    } else {
      authorization = `Bearer ${this.userToken}`;
    }

    const authReq = req.clone({
      headers: new HttpHeaders({
        Authorization: authorization,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Client-ID": this.brandConfiguration.name,
      }),
    });

    return next.handle(authReq);
  }
}
