import { Injectable } from "@angular/core";
import { BlockResponse } from "../state/ui-layout/model";
import { CLIENT_DEPOSIT_VALIDATION } from "../constants/deposit.constant";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() {}

  public isValidUrl(urlString) {
    const pattern =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
    return pattern.test(urlString);
  }

  public getProviderFormat = (theme) => {
    return {
      title: theme.title,
      filter: {
        search: "",
        country: "",
        language: "",
        category: theme.filterCategory,
        tags: theme.filterTags,
        vendor: theme.filterVendor,
        sort: theme.filterSort,
        platform: "",
        pageSize: 24,
        pageIndex: 1,
      },
    };
  };

  public getBlockFormat = (block: BlockResponse) => {
    return {
      type: block.type,
      title: block.title,
      order: block.order,
      filter: {
        category: block.filterCategory,
        vendor: block.filterVendor,
        sort: block.filterSort,
        tags: block.filterTags,
      },
      isLoading: true,
      load: false,
    };
  };

  public isDepositValidationRequired(clientName: string) {
    return (
      CLIENT_DEPOSIT_VALIDATION.find((client) => client === clientName) !=
      undefined
    );
  }
}
