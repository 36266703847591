import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FilterGames } from "src/app/models/filters.model";

@Component({
  selector: "app-card-image",
  templateUrl: "./card-image.component.html",
  styleUrls: ["./card-image.component.scss"],
})
export class CardImageComponent implements OnInit {
  @Input() public image: string;
  @Input() public title: string;
  @Input() public filter: FilterGames;

  constructor(private router: Router) {}

  ngOnInit() {}

  goToGames() {
    this.router.navigate(["/games"], {
      queryParams: {
        title: this.title,
        tags: this.filter.tags || undefined,
        category: this.filter.category || undefined,
        vendor: this.filter.vendor || undefined,
      },
    });
  }
}
