import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  slidesOnDesktop,
  slidesOnMobile,
} from "src/app/constants/slider.constant";

import { BlockModel } from "src/app/models/games.model";
import { SlideConfig } from "src/app/models/slider.model";
import { AppState } from "src/app/models/state.model";

import { DetectDeviceService } from "src/app/services/detect-device.service";
import { BrandConfiguration } from "src/app/state/ui-layout/model";
import { selectUiLayoutState } from "src/app/state/ui-layout/selector/ui-layout.selector";

@Component({
  selector: "app-game-themes-block",
  templateUrl: "./game-themes-block.component.html",
  styleUrls: ["./game-themes-block.component.scss"],
})
export class GameThemesBlockComponent implements OnInit {
  public blockThemes: BlockModel = {
    title: "themes.title",
    themes: [],
  };
  public slideConfig: SlideConfig;
  public isMobile: boolean;

  public brandConfiguration: BrandConfiguration;

  constructor(
    private _detectDeviceService: DetectDeviceService,
    private _store: Store<AppState>
  ) {
    this._store.select(selectUiLayoutState).subscribe((state) => {
      this.brandConfiguration = state.brandConfiguration;
    });
  }

  ngOnInit() {
    this.isMobile = this._detectDeviceService.getIsMobile();
    this.blockThemes.themes = this.brandConfiguration.layout.themes.length
      ? this.brandConfiguration.layout.themes.slice(
          0,
          this.isMobile ? slidesOnMobile : slidesOnDesktop - 1
        )
      : [];

    this.slideConfig = new SlideConfig(<SlideConfig>{
      slidesToScroll: 5,
      slidesToShow: 5,
      nextArrow: '<div class="nav-btn next-slide"></div>',
      prevArrow: '<div class="nav-btn prev-slide"></div>',
      infinite: false,
      dots: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
            nextArrow: "",
            prevArrow: "",
            dots: false,
          },
        },
      ],
    });
  }
}
