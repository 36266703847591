import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class NavigationHistoryService {
  private history: string[] = [];

  constructor(private _router: Router, private _location: Location) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects !== this.history[this.history.length - 1]) {
          this.history.push(event.urlAfterRedirects);
        }
      }
    });
  }

  back(): void {
    this.history = this.history.slice(0, -1);
    if (this.history.length > 0) {
      this._router.navigateByUrl(this.history[this.history.length - 1]);
    } else {
      this._router.navigateByUrl("/");
    }
  }
}
