import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject } from "rxjs";

import { AppState } from "../models/state.model";
import io from "socket.io-client";
import { selectCustomerState } from "../state/costumer/selector/customer.selector";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SocketGameServicesService {
  private socket: any;
  private _tpiEvents = new BehaviorSubject<any>(null);
  tpiEvents = this._tpiEvents.asObservable();
  private username: string = "";

  constructor(private store: Store<AppState>) {}

  InitConnect() {
    this.store.select(selectCustomerState).subscribe((state) => {
      const user = atob(state.userToken);
      this.username = user.substr(0, user.indexOf(":"));
    });
    try {
      if (this.socket) return;
      this.socket = io(environment.SOCKET_SERVER_URL, {
        query: `auth=${this.username}`,
      });

      this.socket.on("events", (data) => {
        if (data && data.Event && data.Event.Id == 1) {
          this._tpiEvents.next(data);
          this._tpiEvents.next(null);
        }
      });

      this.socket.on("connect", () => {
        // console.log("connect", this.socket.id);
      });

      this.socket.on("disconnect", () => {
        io._reconnection = true;
      });
    } catch (ex) {}
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
