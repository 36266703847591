import { blockType } from "src/app/enums/block-types.enum";
import { BlockModel } from "src/app/models/games.model";

export const SlotsBlocks: BlockModel[] = [
  {
    title: "page.slots.featuredSlots",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "SLOTS,TopPicks",
      vendor: "",
      sort: "",
      tags: "",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.slots.newestSlots",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "SLOTS,new",
      vendor: "",
      sort: "",
      tags: "",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.slots.bonusBuySlots",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "SLOTS",
      vendor: "",
      sort: "",
      tags: "bonus buy",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.slots.allSlots",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "SLOTS",
      vendor: "",
      sort: "",
      tags: "",
    },
    games: [],
    isLoading: true,
    load: false,
  },
];
