import { ActionReducerMap } from "@ngrx/store";
import { AppState } from "./app.model";
import { bonusesReducer } from "./bonus/reducers/bonus.reducer";

import { customerReducer } from "./costumer/reducers/customer.reducer";
import { uiLayoutReducer } from "./ui-layout/reducers/ui-layout.reducer";

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  bonuses: bonusesReducer,
  uiLayout: uiLayoutReducer,
  customer: customerReducer,
};
