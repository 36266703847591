import { createAction, props } from "@ngrx/store";
import { BlockModel, Game } from "src/app/models/games.model";
import {
  CustomerProvidersGamificationResponse,
  ProvidersGamification,
} from "src/app/models/providers.model";

export const updateUserInfoAction = createAction(
  "[Customer] Update Customer Info",
  props<{
    loadFirstTime: boolean;
    userToken: string;
    playerId: string;
    agentId: string;
    language: string;
    country: string;
  }>()
);

export const setGamificationTokenAction = createAction(
  "[Customer] Set Gamification Token",
  props<{ gamificationToken: string }>()
);

export const setLastPlayedGamesAction = createAction(
  "[Customer] Set Last Played Games",
  props<{ block: BlockModel }>()
);

export const setFavoriteGamesAction = createAction(
  "[Customer] Set Favorite Games",
  props<{ block: BlockModel }>()
);

export const setFavoriteGameAction = createAction(
  "[Customer] Set Favorite Game",
  props<{ game: Game }>()
);

export const removeFavoriteGameAction = createAction(
  "[Customer] Remove Favorite Game",
  props<{ gameExternalId: string }>()
);

export const updateCustomerProvidersAction = createAction(
  "[Customer] Update Customer Providers",
  props<{ customerProviders: ProvidersGamification[] }>()
);

export const setIsDemoUserAction = createAction(
  "[Customer] Set Is Demo User",
  props<{ isDemoUser: boolean }>()
);

export const setAllGamesUserAction = createAction(
  "[Customer] Set All Games User",
  props<{ allGames: Game[] }>()
);

export const setDepositValidationAction = createAction(
  "[Customer] Set Deposit Validation",
  props<{ depositValidation: boolean }>()
);
