import { blockType } from "src/app/enums/block-types.enum";
import { BlockModel } from "src/app/models/games.model";

export const TableGamesBlocks: BlockModel[] = [
  {
    title: "page.tableGames.blackjack",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "TABLEGAMES",
      vendor: "",
      sort: "",
      tags: "blackjack",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.tableGames.roulette",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "TABLEGAMES",
      vendor: "",
      sort: "",
      tags: "roulette",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.tableGames.baccarat",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "TABLEGAMES",
      vendor: "",
      sort: "",
      tags: "baccarat",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.tableGames.poker",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "TABLEGAMES",
      vendor: "",
      sort: "",
      tags: "poker",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  ,
  {
    title: "page.tableGames.allTableGames",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "TABLEGAMES",
      vendor: "",
      sort: "",
      tags: "",
    },
    games: [],
    isLoading: true,
    load: false,
  },
];
