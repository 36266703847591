import { Component, Input, OnInit } from "@angular/core";
import { Snackbar } from "src/app/models/ui-layout.model";

@Component({
  selector: "app-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent implements OnInit {
  @Input() snackbar: Snackbar;

  constructor() {}

  ngOnInit() {}
}
