import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/models/state.model";

import { setModalAction } from "src/app/state/ui-layout/actions/ui-layout.action";
import { Modal } from "src/app/state/ui-layout/model";
import { selectUiLayoutState } from "src/app/state/ui-layout/selector/ui-layout.selector";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  public modal: Modal;

  constructor(private _store: Store<AppState>) {
    this._store.select(selectUiLayoutState).subscribe((state) => {
      this.modal = state.modal;
    });
  }

  closeModal() {
    this._store.dispatch(
      setModalAction({
        modal: {
          open: false,
          title: "",
          description: "",
          yPosition: 1,
        },
      })
    );
  }

  ngOnInit() {}
}
