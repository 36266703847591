import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { AppState } from "src/app/models/state.model";
import { DetectDeviceService } from "src/app/services/detect-device.service";
import { NavModel } from "./data/nav.data";

import { BrandConfiguration } from "src/app/state/ui-layout/model";
import { selectUiLayoutState } from "src/app/state/ui-layout/selector/ui-layout.selector";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent {
  public nav: NavModel[] = [];
  public brandConfiguration: BrandConfiguration;
  public urlParam: boolean;

  @ViewChild("nav", { static: false }) navElement: ElementRef;

  constructor(
    private _router: Router,
    private _detectDeviceService: DetectDeviceService,
    private _store: Store<AppState>
  ) {
    this._store.select(selectUiLayoutState).subscribe((state) => {
      this.brandConfiguration = state.brandConfiguration;
      if (
        state.brandConfiguration.layout.bonus &&
        state.brandConfiguration.layout.bonus.hidePage
      ) {
        this.nav = state.brandConfiguration.menu.filter(
          (item) => item.id != "bonuses"
        );
      } else {
        this.nav = state.brandConfiguration.menu;
      }
    });
  }

  ngAfterViewInit() {
    if (this._detectDeviceService.getIsMobile()) {
      this.scrollToItemMenu();
    }
  }

  scrollToItemMenu() {
    let currentUrl = this._router.url.replace("/", "");
    if (currentUrl) {
      const navItem = document.getElementById(currentUrl);
      if (navItem) {
        navItem.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }
}
