import { blockType } from "src/app/enums/block-types.enum";
import { BlockModel } from "src/app/models/games.model";

export const LiveDealersBlocks: BlockModel[] = [
  {
    title: "page.live.liveBlackjack",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "LIVEDEALERS",
      vendor: "",
      sort: "",
      tags: "blackjack",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.live.liveRoulette",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "LIVEDEALERS",
      vendor: "",
      sort: "",
      tags: "roulette",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.live.liveBaccarat",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "LIVEDEALERS",
      vendor: "",
      sort: "",
      tags: "baccarat",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.live.liveGameShows",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "LIVEDEALERS",
      vendor: "",
      sort: "",
      tags: "game shows",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.live.liveCraps",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "LIVEDEALERS",
      vendor: "",
      sort: "",
      tags: "craps",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.live.livePoker",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "LIVEDEALERS",
      vendor: "",
      sort: "",
      tags: "poker",
    },
    games: [],
    isLoading: true,
    load: false,
  },
  {
    title: "page.live.allLiveDealerGames",
    type: blockType.GAMES_BY_FILTERS,
    filter: {
      category: "LIVEDEALERS",
      vendor: "",
      sort: "",
      tags: "",
    },
    games: [],
    isLoading: true,
    load: false,
  },
];
