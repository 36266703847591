import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

import { Observable } from "rxjs";
import {
  GameLaunchPayload,
  GameLaunchResponse,
  InitProductPayload,
  InitProductResponse,
} from "../models/init-product.model";

@Injectable({ providedIn: "root" })
export class InitProductService {
  private initProductApiUrl: string =
    environment.JAZZ_APIS.SERVER_URL + environment.JAZZ_APIS.APIS.INIT_PRODUCT;

  private GAME_LAUNCH_URL: string =
    environment.PROVIDER_INTEGRATION_API.SERVER_URL +
    environment.PROVIDER_INTEGRATION_API.APIS.GAME_LAUNCH;

  constructor(public http: HttpClient) {}

  public initProduct(
    data: InitProductPayload
  ): Observable<InitProductResponse> {
    return this.http.post<InitProductResponse>(
      this.initProductApiUrl,
      JSON.stringify(data)
    );
  }

  public gameLaunch(data: GameLaunchPayload): Observable<GameLaunchResponse> {
    return this.http.post<GameLaunchResponse>(
      this.GAME_LAUNCH_URL,
      JSON.stringify(data),
      {
        headers: {
          "Ocp-Apim-Subscription-Key":
            environment.PROVIDER_INTEGRATION_API.TOKEN,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
  }
}
