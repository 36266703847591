import { Pipe, PipeTransform } from "@angular/core";
import { Game } from "src/app/models/games.model";

@Pipe({
  name: "search",
})
export class SearchPipe implements PipeTransform {
  transform(allGames: Game[], value: string): Game[] {
    if (!value) return allGames;
    return allGames.filter((game) =>
      game.name
        .toLocaleUpperCase()
        .includes(value.toString().toLocaleUpperCase())
    );
  }
}
