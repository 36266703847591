import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";
import {
  GetDepositValidationPayload,
  GetDepositValidationResponse,
} from "../models/deposit.model";

@Injectable({
  providedIn: "root",
})
export class DepositService {
  private getDepositValidationApiUrl: string =
    environment.JAZZ_APIS.SERVER_URL +
    environment.JAZZ_APIS.APIS.DEPOSIT_VALIDATION;

  constructor(public http: HttpClient) {}

  public getDepositValidation(
    data: GetDepositValidationPayload
  ): Observable<GetDepositValidationResponse> {
    return this.http.post<GetDepositValidationResponse>(
      this.getDepositValidationApiUrl,
      JSON.stringify(data)
    );
  }
}
