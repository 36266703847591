import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/models/state.model";
import {
  BrandConfiguration,
  UiLayoutState,
} from "src/app/state/ui-layout/model";

export const selectUiLayoutState = (state: AppState) => state.uiLayout;

export const selectIsLoadingUiLayout = createSelector(
  selectUiLayoutState,
  (state: UiLayoutState) => state.isLoading
);

export const selectIsWebsiteLoadingUiLayout = createSelector(
  selectUiLayoutState,
  (state: UiLayoutState) => state.isWebsiteLoading
);

export const selectBannersUiLayout = createSelector(
  selectUiLayoutState,
  (state: UiLayoutState) => state.bannerLayout
);

export const selectModalUiLayout = createSelector(
  selectUiLayoutState,
  (state: UiLayoutState) => state.modal
);

export const selectBrandConfiguration = createSelector(
  selectUiLayoutState,
  (state: UiLayoutState) => state.brandConfiguration
);
