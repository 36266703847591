import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BannersResponse, GetBannersPayload } from "../models/banner.model";

import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class BannersService {
  private getBannersApiUrl: string =
    environment.GAMIFICATION_API.SERVER_URL +
    environment.GAMIFICATION_API.APIS.GET_BANNERS;

  constructor(public http: HttpClient) {}

  public getBanners(data: GetBannersPayload): Observable<BannersResponse> {
    return this.http.post<BannersResponse>(
      this.getBannersApiUrl,
      JSON.stringify(data)
    );
  }
}
