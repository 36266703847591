import { menuType } from "src/app/enums/menu-types.enum";
import { NavModel } from "./nav.data";
import { LiveDealersBlocks } from "src/app/pages/live-dealers/live-dealers.data";

import { SlotsBlocks } from "src/app/pages/slots/slots.data";
import { TableGamesBlocks } from "src/app/pages/table-games/table-games.data";

export const iBetSportNavData: NavModel[] = [
  {
    name: "navigation.gameLobby",
    slug: "",
    id: "lobby",
    type: menuType.BLOCKS,
    blocks: [],
  },
  {
    name: "navigation.tableGames",
    slug: "/table-games",
    id: "table-games",
    type: menuType.BLOCKS,
    blocks: TableGamesBlocks,
  },
  {
    name: "navigation.slots",
    slug: "/slots",
    id: "slots",
    type: menuType.BLOCKS,
    blocks: SlotsBlocks,
  },
  {
    name: "navigation.liveDealers",
    slug: "/live-dealers",
    id: "live-dealers",
    type: menuType.BLOCKS,
    blocks: LiveDealersBlocks,
  },

  {
    name: "navigation.videoPoker",
    slug: "/video-poker",
    id: "video-poker",
    type: menuType.BLOCKS,
    blocks: [],
  },
  {
    name: "navigation.history",
    slug: "/history",
    id: "history",
    type: menuType.HISTORY,
    blocks: [],
  },
];
