import { Banner, BannerLayoutState } from "src/app/models/banner.model";
import { Component, OnInit } from "@angular/core";
import { SlideConfig } from "src/app/models/slider.model";

import { BannersService } from "src/app/services/banners.service";
import { AppState } from "src/app/models/state.model";
import { Store } from "@ngrx/store";

import {
  selectBannersUiLayout,
  selectUiLayoutState,
} from "src/app/state/ui-layout/selector/ui-layout.selector";
import { setBannersAction } from "src/app/state/ui-layout/actions/ui-layout.action";
import { selectCustomerState } from "src/app/state/costumer/selector/customer.selector";

import { SkeletonType } from "src/app/enums/skeleton-loader.enum";
import { BrandConfiguration } from "src/app/state/ui-layout/model";
import { Router } from "@angular/router";

import { BannerType } from "src/app/enums/banner-type.enum";
import { OPENED_PROVIDERS_NEW_TAB } from "src/app/enums/providers.enum";
import { GameService } from "src/app/services/game.service";

import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
  public bannerLayout: BannerLayoutState;
  public slideConfig: SlideConfig;
  public isMobile: boolean;

  public brandConfiguration: BrandConfiguration;
  public language: string;
  public skeletonBlock: SkeletonType = SkeletonType.BANNER;

  public showBanner: boolean = false;
  public isDemoUser: boolean;

  constructor(
    public _bannersService: BannersService,
    private router: Router,
    private store: Store<AppState>,
    public _gameService: GameService,
    _deviceService: DeviceDetectorService
  ) {
    this.store.select(selectBannersUiLayout).subscribe((state) => {
      this.bannerLayout = state;
    });

    this.store.select(selectUiLayoutState).subscribe((state) => {
      this.brandConfiguration = state.brandConfiguration;
      this.showBanner = !state.brandConfiguration.layout.hideBanner;
    });

    this.store.select(selectCustomerState).subscribe((state) => {
      this.language = state.language;
      this.isDemoUser = state.isDemoUser;
    });
    this.isMobile = _deviceService.isMobile();
  }

  ngOnInit() {
    if (this.showBanner && !this.bannerLayout.load) {
      this.getBanners();
    }
    this.isMobile = window.innerWidth > 580 ? false : true;
    this.slideConfig = new SlideConfig(<SlideConfig>{
      slidesToScroll: 2,
      nextArrow: '<div class="nav-btn next-slide"></div>',
      prevArrow: '<div class="nav-btn prev-slide"></div>',
      dots: true,
      centerMode: true,
      infinite: true,
      autoRotate: true,
      autoRotateAfter: 2000,
      autoplay: true,
      autoplaySpeed: 5000,
      variableWidth: true,
      variableHeight: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            variableHeight: false,
            centerMode: false,
          },
        },
      ],
    });
  }

  getBanners() {
    this.store.dispatch(
      setBannersAction({
        bannerLayout: {
          banners: [],
          isLoading: true,
          load: false,
        },
      })
    );

    this._bannersService
      .getBanners({
        operatorID: this.brandConfiguration.operatorId,
        lang: this.language,
        category: "",
        dimension: "",
      })
      .subscribe((response) => {
        if (response && response.banners.length) {
          this.store.dispatch(
            setBannersAction({
              bannerLayout: {
                banners: response.banners
                  .sort((a, b) => b.order - a.order)
                  .reverse(),
                isLoading: false,
                load: true,
              },
            })
          );
        } else {
          this.store.dispatch(
            setBannersAction({
              bannerLayout: {
                banners: [],
                isLoading: false,
                load: true,
              },
            })
          );
        }
      });
  }

  onBannerClick(banner: Banner) {
    switch (banner.bannerType) {
      case BannerType.NO_ACTION_BANNER:
        break;

      case BannerType.OPEN_NEW_TAB_BANNER:
        const newTabURL = banner.link;
        window.open(newTabURL, "_blank");
        break;

      case BannerType.OPEN_POPUP_BANNER:
        //todo: open pop up
        //window.open(banner.externalPopUpID, "popup", "width=600,height=400");
        break;

      case BannerType.REDIRECT_TO_PROVIDER_BANNER:
        this.router.navigate(["/games"], {
          queryParams: {
            title: banner.name,
            vendor: banner.externalProviderID,
          },
        });
        break;

      case BannerType.REDIRECT_TO_INTERNAL_PAGE_BANNER:
        const internalPageUrl = banner.internalPageURL;
        this.router.navigate([internalPageUrl]);
        break;

      case BannerType.OPEN_GAME_BANNER:
      case BannerType.OPEN_LIVE_LOBBY_BANNER:
        if (this.isDemoUser) return;
        if (
          this.isMobile &&
          Object.values(OPENED_PROVIDERS_NEW_TAB).includes(
            banner.externalProviderID as OPENED_PROVIDERS_NEW_TAB
          )
        ) {
          this._gameService.openNewTab(banner.externalGameID);
        } else {
          const gameId = banner.externalGameID;
          const gameUrl = `/game/${gameId}`;
          this.router.navigate([gameUrl]);
        }
        break;

      default:
        console.warn("Unknown bannerType:", banner.bannerType);
    }
  }
}
