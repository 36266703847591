import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { DeviceDetectorService } from "ngx-device-detector";
import { DeviceTypeInitProduct } from "src/app/enums/device-type.enum";
import { OPENED_PROVIDERS_NEW_TAB } from "src/app/enums/providers.enum";

import { Game } from "src/app/models/games.model";
import { AppState } from "src/app/models/state.model";
import { GameService } from "src/app/services/game.service";

import { selectCustomerState } from "src/app/state/costumer/selector/customer.selector";
import { setModalAction } from "src/app/state/ui-layout/actions/ui-layout.action";
import { BrandConfiguration } from "src/app/state/ui-layout/model";

import { selectUiLayoutState } from "src/app/state/ui-layout/selector/ui-layout.selector";

@Component({
  selector: "app-horizontal-result-card",
  templateUrl: "./horizontal-result-card.component.html",
  styleUrls: ["./horizontal-result-card.component.scss"],
})
export class HorizontalResultCardComponent {
  @Input() public game: Game;
  private isDemoUser: boolean = false;
  public brandConfiguration: BrandConfiguration;

  public deviceType: DeviceTypeInitProduct;
  public isMobile: boolean;

  constructor(
    private _router: Router,
    private _store: Store<AppState>,
    private readonly _gameService: GameService,
    _deviceService: DeviceDetectorService
  ) {
    this.isMobile = _deviceService.isMobile();

    this._store.select(selectCustomerState).subscribe((state) => {
      this.isDemoUser = state.isDemoUser;
    });
    this._store.select(selectUiLayoutState).subscribe((state) => {
      this.brandConfiguration = state.brandConfiguration;
    });
  }

  goToGame() {
    if (this.isDemoUser) {
      this._store.dispatch(
        setModalAction({
          modal: {
            open: true,
            title: "common.loginRequiredTitle",
            description: "common.loginRequiredMessage",
            size: "xs",
          },
        })
      );
    } else {
      if (
        this.isMobile &&
        Object.values(OPENED_PROVIDERS_NEW_TAB).includes(
          this.game.vendorExternalID as OPENED_PROVIDERS_NEW_TAB
        )
      ) {
        this._gameService.openNewTab(this.game.gameExternalID);
      } else {
        this._router.navigate(["/game/" + this.game.gameExternalID], {
          queryParams: { category: undefined, tag: undefined },
        });
      }
    }
  }
}
